import React from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBIcon } from 'mdbreact';
import CircularProgress from "@material-ui/core/CircularProgress";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import TableComponent from '../../SharedComponents/Table';
import BasicCheckbox from '../../../components/SharedComponents/BasicCheckbox/BasicCheckbox';
import BasicTextArea from '../../../components/SharedComponents/BasicTextArea/BasicTextArea';
import BasicTextField from '../../../components/SharedComponents/BasicTextField/BasicTextField';
import BasicLabel from '../../../components/SharedComponents/BasicLabel/BasicLabel';
import BasicButton from '../../../components/SharedComponents/BasicButton/BasicButton';
import SearchSelectField from '../../../components/SharedComponents/SearchSelectField';
import SelectField from '../../../components/SharedComponents/SelectField/SelectField';
import messages from '../../../Common/Messages.json';
import clearTrackService from '../../../services/service';
import MainViewModalComponent from "../../../Common/MainViewModalLayout/Modal/Modal";
import { validateNumDotOnly, validateNumbersOnly } from "../../../Common/Helper";
import CancelIcon from '@material-ui/icons/Cancel';
import {
    LicenseHeading,
    CueSheetHeading,
    PerformerHeading,
    LicensorsWayneHeading,
    PdComposers,
    songDetails,
    publicDomainSongDetails,
    writersList,
    musicTypes,
} from './config';
import PublicDomainSong from './PublicDomainSong';
import './EditSong.scss';
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import { withUserContext } from '../../../contexts/UserContext';

import axios from 'axios';
import MessageModal from '../../SharedComponents/MessageModal';
let CanOpenUploadFile = false, queryParam = {}, formDataLyrics = null, formDataMusic = null, fileToReplaceMusicBackup = null, fileToReplaceLyricsBackup = null,licensorsToDelete=[],cuesheetPublisherToDelete=[],performersToDelete=[],performerLicensorToDelete=[];
// composersToDelete=[];
class EditSongDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            songId: props?.songId,
            isPd: 0,
            songDetails: this.formatSong(JSON.parse(JSON.stringify({ ...songDetails }))),
            publicDomainSongDetails: this.formatPdSong(JSON.parse(JSON.stringify({ ...publicDomainSongDetails }))),
            writersOption: [],
            musicTypeOptions: [],
            writerIndex: 2,
            musicTypeIndex: 2,
            rendeList: [],
            showMusicFileError: false,
            showLyricsFileError: false,
            licensors_list: [],
            backupLicensorList:[],
            backupPerformerList:[],
            backupComposerList:[],
            isUploadFile: false,
            cueSheetPublisherList: [],
            performerLicensorList: [],
            licensors: [],
            cueSheetPublishers: [],
            affilationOptions: [],
            performers: [],
            pdComposers: [],
            initialRecordData: this.props?.initialRecordData,
            isFetchingSongDetails: false,
            activeWriters: [],
            isLoading: false,
            fileMusicData: null,
            fileLyricsData: null,
            isDeleted: false,
            fileName: null,
            formData: null,
            postInitiated: false,
            filesArr1: [],
            filesArr: [],
            isReplace: false,
            isPosting: false,
            searchCancelToken: null,
            isMusicFileLoading: false,
            isLyricsFileLoading: false,
            deleteFilesForMusic: null,
            deleteFilesForLyrics: null,
            editingSyncLicensors: null,
            editingCueSheetPublishers: null,
            editingPerformers: null,
            editingLicensorsWayne: null,
            MandatorySyncLicensorsError: false,
            MandatoryCueSheetPublishersError: false,
            MandatoryPerformersError: false,
            MandatoryLicensorsWayne: false,
            MandatoryPdComposerError: false,
            writerMode: null,
            selectedWriterIndex: null,
            openSyncLicensorModal: null,
            openCueSheetPubModal: null,
            openPerformerModal: null,
            modalIndex: null,
            selectedLicensor: null,
            openPerformerLicModal: null,
            editWriterNamePermission:true,
            deleteSongRecord: false,
            showError: false,
            deleteSongRecordLoading: false
            // writersForLookUp:[],
        }
    }

    componentDidMount() {
        this.setState({ editWriterNamePermission: !(this.props?.mode?.toLowerCase() === 'edit' && this.props?.userContext?.active_tenant?.division_role?.some(item => item?.role_name === 'Music Supervisor')) });
        if (this.props?.mode === "Edit" && this.state.songId) {
            this.getSongDetails();
        }
        if (this.props.proposedNewSong) {
            this.setState(prevState => ({
                songDetails: {
                    ...prevState.songDetails,
                    ['song_title']: this.props.proposedNewSong,
                },
            }));
        }
        this.getStaticData("affilationOptions", "lu_affiliation");
        this.getStaticData("musicTypeOptions", "lu_public_domain_genre");
        this.getStaticData("cueSheetPublishers", "lu_cue_sheet_publisher")
        this.getStaticData("performers", "lu_performer")
        //     if (this.props?.mode === "Edit" && this.state.songId > 0) {
        //     queryParam = {
        //         "songId": this.props?.songId,
        //         "songName": "Test song for file",
        //         "module": "songs",
        //         "subModule": "music",
        //         "tenantName": this.props.userContext?.active_tenant?.tenant_name,
        //         "isBoxUpload": 1
        //     }
        //     this.onLoadFileData(queryParam);
        //     queryParam = {
        //         "songId": this.props?.songId,
        //         "songName": "Test song for file",
        //         "module": "songs",
        //         "subModule": "lyrics",
        //         "tenantName": this.props.userContext?.active_tenant?.tenant_name,
        //         "isBoxUpload": 1
        //     }
        //     this.onLoadFileData(queryParam);
        // }
    }

    formatSong = (response) => {
        if (response?.writers_list?.length === 0) {
            response?.writers_list.push(JSON.parse(JSON.stringify({ ...writersList })));
        } else {
            response?.writers_list?.map((item) => {
                item['temp_writer_name'] = `${item?.writer_name ? item?.writer_name : ''}${(item?.affiliation_name && item?.affiliation_name != null) ? '-' + item?.affiliation_name : ''} ${(item?.affiliation_name && item?.share_percentage != 0 && item?.share_percentage != null) ? item?.share_percentage : ''}`,
                    item.writer_name = `${item?.writer_name ? item?.writer_name : ''}${(item?.affiliation_name && item?.affiliation_name != null) ? ' (' + item?.affiliation_name + ')' : ''} ${(item?.affiliation_name && item?.share_percentage != 0 && item?.share_percentage != null) ? item?.share_percentage : ''}`

            })
        }
        return response;
    }

    formatPdSong = (response) => {
        if (response?.pd_genre?.length === 0) {
            response?.pd_genre.push(JSON.parse(JSON.stringify({ ...musicTypes })));
        }
        return response;
    }
    onLoadFileData = (identifier, queryParamObj) => {
        if (identifier === "music") {
            this.setState({ isMusicFileLoading: true });
        }
        if (identifier === "lyrics") {
            this.setState({ isLyricsFileLoading: true });
        }
        clearTrackService.getDataParams(Constants.ClearTrackServiceBaseUrl + `/getFiles`, queryParamObj, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                if (identifier === "music") {
                    this.setState({ isMusicFileLoading: false });
                }
                if (identifier === "lyrics") {
                    this.setState({ isLyricsFileLoading: false });
                }
                this.setState({ isLoading: false });
                if (queryParamObj.subModule === "lyrics") {
                    this.setState({ filesArr1: response.data, filesArrBackup: response.data });
                } else {
                    this.setState({ filesArr: response.data, filesArrBackup: response.data });
                }
                // if (!loader) {
                //     this.setState({ openNotification: true, notification: 'File deleted successfully' })
                // }
            },
                (err) => {
                    if (identifier === "music") {
                        this.setState({ isMusicFileLoading: false });
                    }
                    if (identifier === "lyrics") {
                        this.setState({ isLyricsFileLoading: false });
                    }
                    console.log("Error in fetching data", err)
                })
    }
    uploadFileMusic(file, isReplace, fileToReplace = null) {
        this.setState({ showMusicFileError: false })
        try {
            formDataMusic = new FormData();
            formDataMusic.append("files", file[0]);
            let formattedFileList =
                [{
                    "Key": Array.from(file)[0]["name"],
                    "url": "",
                    "id": "1"
                }];
            formDataMusic.append("songName", this.state.songDetails?.song_title ? this.state.songDetails?.song_title : this.state.publicDomainSongDetails?.song_title);
            formDataMusic.append("tenantName", this.props.userContext?.active_tenant?.tenant_name);
            formDataMusic.append("tenantId", this.props.userContext?.active_tenant?.tenant_id);
            formDataMusic.append("module", "songs");
            formDataMusic.append("subModule", "music")
            formDataMusic.append("isBoxUpload", 1)
            if (isReplace === true) {
                formDataMusic.append("isReplaceFile", 1);
                if (fileToReplace.url.length > 0) {
                    fileToReplaceMusicBackup = fileToReplace.Key;
                    formDataMusic.append("replaceFileName", fileToReplace.Key);
                } else {
                    formDataMusic.append("replaceFileName", fileToReplaceMusicBackup);
                }
            }
            this.setState({ filesArr: formattedFileList, fileMusicData: formDataMusic, fileName: Array.from(file).map(item => item.name, formDataMusic) });
            let totalSize = file[0].size;
            if (totalSize > 4194304) {
                this.setState({ showMusicFileError: true });
            } else {
                this.setState({ showMusicFileError: false });
            }
        } catch (error) {
            console.log(error, "cannot upload")
            this.setState({ fileMusicData: null, fileName: [] });
        }
    }
    uploadFileLyrics(file, isReplace, fileToReplace = null) {
        this.setState({ showLyricsFileError: false })
        try {
            formDataLyrics = new FormData();
            formDataLyrics.append("files", file[0]);
            let formattedFileList = [{
                "Key": Array.from(file)[0]["name"],
                "url": "",
                "id": "1",
            }];
            formDataLyrics.append("songName", this.state.songDetails?.song_title ? this.state.songDetails?.song_title : this.state.publicDomainSongDetails?.song_title);
            formDataLyrics.append("tenantName", this.props.userContext?.active_tenant?.tenant_name);
            formDataLyrics.append("tenantId", this.props.userContext?.active_tenant?.tenant_id);
            formDataLyrics.append("module", "songs");
            formDataLyrics.append("subModule", "lyrics")
            formDataLyrics.append("isBoxUpload", 1)
            if (isReplace === true) {
                formDataLyrics.append("isReplaceFile", 1);
                if (fileToReplace.url.length > 0) {
                    fileToReplaceLyricsBackup = fileToReplace.Key;
                    formDataLyrics.append("replaceFileName", fileToReplace.Key);
                } else {
                    formDataLyrics.append("replaceFileName", fileToReplaceLyricsBackup);
                }
            }

            this.setState({ filesArr1: formattedFileList, fileLyricsData: formDataLyrics, fileName: Array.from(file).map(item => item.name, formDataLyrics) });
            let totalSize = file[0].size;
            if (totalSize > 4194304) {
                this.setState({ showLyricsFileError: true })
            } else {
                this.setState({ showLyricsFileError: false })
            }
        } catch (error) {
            console.log(error, "cannot upload")
            this.setState({ fileLyricsData: null, fileName: [] });
        }
    }
    deleteFilesForMusic = (file) => {
        if (file.url.length > 0) {
            this.setState({ filesArr: [], deleteFilesForMusic: file.Key, showMusicFileError: false })
        }
        else {
            this.setState({ filesArr: [], deleteFilesForMusic: null, showMusicFileError: false })
        }
    }
    deleteFilesForLyrics = (file) => {
        if (file.url.length > 0) {
            this.setState({ filesArr1: [], deleteFilesForLyrics: file.Key, showLyricsFileError: false })
        }
        else {
            this.setState({ filesArr1: [], deleteFilesForLyrics: null, showLyricsFileError: false })
        }
    }
    deleteAPICall = (subModule) => {

        let queryParamObj = {
            "songId": this.state.songDetails?.song_id,
            "songName": this.state.songDetails?.song_title,
            "module": "songs",
            "subModule": subModule,
            "tenantName": this.props.userContext?.active_tenant?.tenant_name,
            "tenantId": this.props.userContext?.active_tenant?.tenant_id,
            "isBoxUpload": 1
        }
        if (subModule === "music") {
            if (this.state.deleteFilesForMusic !== null) {
                queryParamObj.fileName = this.state.deleteFilesForMusic;
            }
            if (fileToReplaceMusicBackup !== null) {
                queryParamObj.fileName = fileToReplaceMusicBackup;
            }
        }
        if (subModule === "lyrics") {
            if (this.state.deleteFilesForLyrics !== null) {
                queryParamObj.fileName = this.state.deleteFilesForLyrics;
            }
            if (fileToReplaceLyricsBackup !== null) {
                queryParamObj.fileName = fileToReplaceLyricsBackup;
            }
        }

        clearTrackService.deleteDataParams(Constants.ClearTrackServiceBaseUrl + '/deleteFiles', queryParamObj, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id)
            .then(response => {
                if (response.data.error) {
                    this.setState({ openNotification: true, notification: 'File deletion failed', isSubmit: false })
                }
            },
                (err) => {
                    this.setState({ openNotification: true, notification: 'File deletion failed', isSubmit: false });
                    console.log("Error in fetching data", err)
                })
    }

    uploadFileAPICallForMusic = (songId) => {
        this.setState({ isPosting: true });
        // let fileData = [...this.state.fileMusicData];
        // fileData.push(["songId", "" + songId + ""]);
        if (formDataMusic !== null) {
            formDataMusic.append("songId", songId);
        }
        let extensionUrl = '/uploadFile';
        clearTrackService.uploadFileFormData(Constants.ClearTrackServiceBaseUrl + extensionUrl, formDataMusic, this.props.userContext?.active_tenant?.tenant_id).then((response) => {
            if (response.data.error) {
                this.setState({ isPosting: false, isSubmit: false });
                this.props?.notificationComponent(true, "fail");
            } else {
                this.setState({ isPosting: false, isSubmit: false });
                formDataMusic = null;
            }
        },
            (err) => {
                this.setState({ isPosting: false, isSubmit: false });
                this.props?.notificationComponent(true, "fail");
                console.log("Post agency details error: " + err);
            });
    }
    uploadFileAPICallForlyrics = (songId) => {
        this.setState({ isPosting: true });
        let extensionUrl = '/uploadFile';
        //let fileData = [...this.state.fileLyricsData];
        //fileData.push(["songId", "" + songId + ""]);
        if (formDataLyrics !== null) {
            formDataLyrics.append("songId", songId);
        }
        clearTrackService.uploadFileFormData(Constants.ClearTrackServiceBaseUrl + extensionUrl, formDataLyrics, this.props.userContext?.active_tenant?.tenant_id)
            .then((response) => {
                if (response.data.error) {
                    this.setState({ isPosting: false, isSubmit: false });

                    this.props?.notificationComponent(true, "fail");
                } else {
                    this.setState({ isPosting: false, isSubmit: false });
                    formDataLyrics = null;
                }
            },
                (err) => {
                    this.setState({ isPosting: false, isSubmit: false });
                    this.props?.notificationComponent(true, "fail");
                    console.log("Post agency details error: " + err);
                });
    }
    handleInlineEdits = (tableName, id, dataItem) => {
        switch (tableName) {
            case 'Sync-Licensors': {
                let licensors_list = this.state.songDetails?.licensors_list?.map(item => {
                    let newItem = { ...item }
                    if (item.song_licensor_id === dataItem.song_licensor_id && item.licensor === dataItem.licensor) {
                        newItem.licensor = {
                            text: dataItem?.licensor_name,
                            value: dataItem?.licensor?.hasOwnProperty('value') ? dataItem?.licensor?.value : dataItem?.licensor
                        };
                        newItem['editing'] = true;
                        //this.setState({ "editingSyncLicensors": true });
                    } else {
                        newItem['editing'] = false;
                    }
                    return newItem;
                })

                this.setState(prevState => ({
                    songDetails: {
                        ...prevState.songDetails,
                        licensors_list: [...licensors_list] || [],
                    },
                    "editingSyncLicensors": true
                }))
                LicenseHeading.headings[0].selectOptions = [];
            }
                break;
            case 'Cue-Sheet-Publishers': {
                let cueSheetPublisherList = [...this.state.cueSheetPublisherList]
                this.setState({
                    "editingCueSheetPublishers": true,
                    cueSheetPublisherList: cueSheetPublisherList.map((item) => {
                        let newItem = { ...item }
                        if (item.song_cue_sheet_publisher_id == dataItem.song_cue_sheet_publisher_id &&
                            item.cue_sheet_publisher === dataItem.cue_sheet_publisher
                        ) {
                            newItem.cue_sheet_publisher = {
                                text: dataItem.cue_sheet_publisher_name,
                                value: dataItem?.cue_sheet_publisher?.hasOwnProperty('value') ? dataItem?.cue_sheet_publisher?.value : dataItem?.cue_sheet_publisher
                            };
                            newItem['editing'] = true
                            //this.setState({ "editingCueSheetPublishers": true });
                        } else {
                            newItem['editing'] = false;
                        }
                        return newItem;
                    })
                })
                CueSheetHeading.headings[1].selectOptions = [];
            }
                break;
            case 'performers': {
                let performers_list = this.state.songDetails?.performers_list?.map(item => {
                    let newItem = { ...item }
                    if (item.song_performer_id === dataItem.song_performer_id && item.performer === dataItem.performer) {
                        newItem.performer = {
                            text: dataItem.performer_name,
                            value: dataItem?.performer?.hasOwnProperty('value') ? dataItem?.performer?.value : dataItem?.performer
                        };
                        newItem['editing'] = true;
                        //this.setState({ "editingPerformers": true });
                    } else {
                        newItem['editing'] = false;
                    }
                    return newItem;
                })

                this.setState(prevState => ({
                    songDetails: {
                        ...prevState.songDetails,
                        performers_list: performers_list || [],
                    },
                    "editingPerformers": true
                }))
                PerformerHeading.headings[1].selectOptions = [];
            }
                break;
            case 'Licensors-Wayne': {
                let performerLicensorList = [...this.state.performerLicensorList]
                this.setState({
                    "editingLicensorsWayne": true,
                    performerLicensorList: performerLicensorList.map((item) => {
                        let newItem = { ...item }
                        if (item.performer_licensor_id == dataItem.performer_licensor_id &&
                            item.performer_licensor === dataItem.performer_licensor
                        ) {
                            newItem.performer_licensor = {
                                text: dataItem.performer_licensor_name,
                                value: dataItem.performer_licensor
                            };
                            newItem['editing'] = true;
                            //this.setState({ "editingLicensorsWayne": true });
                        }
                        return newItem
                    })
                })
                LicensorsWayneHeading.headings[1].selectOptions = [];
            }
                break;
            case 'pd-composers': {
                let pd_composer = this.state.publicDomainSongDetails?.pd_composer?.map(item => {
                    let newItem = { ...item }
                    if (item.pd_composer === dataItem.pd_composer && item.song_pd_composer_id === dataItem.song_pd_composer_id) {
                        newItem.pd_composer = {
                            text: dataItem.pd_composer_name,
                            value: dataItem.pd_composer
                        };
                        newItem['editing'] = true;
                        //this.setState({ "editingPdComposers": true });
                    }
                    return newItem;
                })

                this.setState(prevState => ({
                    publicDomainSongDetails: {
                        ...prevState.publicDomainSongDetails,
                        pd_composer: pd_composer || [],
                    },
                    "editingPdComposers": true
                }))
                PdComposers.headings[0].selectOptions = [];
            }
                break;
        }
    }
    handleInlineDelete  = (id,dataItem,rowIndex,tableName) => {
        switch (tableName) {
            case 'Sync-Licensors': {
                if(this.state.songDetails?.licensors_list?.length>0){
                    let licensors_list=JSON.parse(JSON.stringify(this.state.songDetails?.licensors_list));
                        if(typeof dataItem?.song_licensor_id==="number"){
                            licensorsToDelete.push(dataItem?.song_licensor_id);
                        }
                        licensors_list?.splice(rowIndex,1);
                        this.setState(prevState => ({
                            songDetails: {
                                ...prevState.songDetails,
                                licensors_list: licensors_list
                            }    
                        }),()=>{
                            this.setState({
                                selectedLicensor:this.state.songDetails?.licensors_list?.length>0?this.state.songDetails?.licensors_list?.[0]:null,
                                cueSheetPublisherList:this.state.songDetails?.licensors_list?.length>0?this.state.songDetails?.licensors_list?.[0]?.cue_sheet_publisher_list:[],
                            })
                        })
                }
            }
                break;
            case 'Cue-Sheet-Publishers': {
                if(this.state.cueSheetPublisherList?.length>0){
                    let licensors_list=JSON.parse(JSON.stringify(this.state.songDetails?.licensors_list));
                    let licensorIndex=null;
                    this.state.songDetails?.licensors_list?.map((item1,index1)=>{
                        if(item1.song_licensor_id===this.state.selectedLicensor.song_licensor_id){
                            licensorIndex=index1;
                            item1?.cue_sheet_publisher_list?.map((item2)=>{
                                if(item2.song_cue_sheet_publisher_id===dataItem?.song_cue_sheet_publisher_id && typeof dataItem?.song_cue_sheet_publisher_id==="number"){
                                    cuesheetPublisherToDelete.push({"song_licensor_id":item1.song_licensor_id,"cue_sheet_publisher":item2});
                                    return;
                                }
                            })
                        }
                    })
                    licensors_list[licensorIndex].cue_sheet_publisher_list.splice(rowIndex,1);
                    
                    let cuesheet_publisher_list=licensors_list[licensorIndex].cue_sheet_publisher_list;
                    this.setState(prevState => ({
                        songDetails: {
                            ...prevState.songDetails,
                            licensors_list: licensors_list,
                        },
                        cueSheetPublisherList: cuesheet_publisher_list,
                    }))
                }
            }
                break;
            case 'performers': {
                if(this.state.songDetails?.performers_list?.length>0){
                    let performers_list=JSON.parse(JSON.stringify(this.state.songDetails?.performers_list));
                        if(typeof dataItem?.song_performer_id==="number"){
                            performersToDelete.push(dataItem?.song_performer_id);
                        }
                        performers_list?.splice(rowIndex,1);
                    this.setState(prevState => ({
                        songDetails: {
                            ...prevState.songDetails,
                            performers_list: performers_list,
                        }
                    }),()=>{
                        this.setState({
                            selectedPerformer:this.state.songDetails?.performers_list?.length>0?this.state.songDetails?.performers_list?.[0]:null,
                            performerLicensorList:this.state.songDetails?.performers_list?.length>0?this.state.songDetails?.performers_list?.[0]?.performer_licensor_list:[],
                        })
                    })
                }
            }
                break;
            case 'Licensors-Wayne': {
                if(this.state.performerLicensorList?.length>0){
                    let performers_list=JSON.parse(JSON.stringify(this.state.songDetails?.performers_list));
                    let performerIndex=null;
                    this.state.songDetails?.performers_list?.map((item1,index1)=>{
                        if(item1.song_performer_id===this.state.selectedPerformer.song_performer_id){
                            performerIndex=index1;
                            item1?.performer_licensor_list?.map((item2)=>{
                                if(item2.performer_licensor_id===dataItem?.performer_licensor_id && typeof dataItem?.performer_licensor_id==="number"){
                                    performerLicensorToDelete.push({"song_performer_id":item1.song_performer_id,"performer_licensor":item2});
                                    return;
                                }
                            })
                        }
                    })
                    performers_list[performerIndex].performer_licensor_list.splice(rowIndex,1);
                    
                    let performer_licensor_list=performers_list[performerIndex].performer_licensor_list;
                    this.setState(prevState => ({
                        songDetails: {
                            ...prevState.songDetails,
                            performers_list: performers_list,
                        },
                        performerLicensorList: performer_licensor_list,
                    }))
                }
            }
                break;
            // case 'pd-composers': {
            //     if(this.state.publicDomainSongDetails?.pd_composer?.length>0){
            //         let pd_composer_list=JSON.parse(JSON.stringify(this.state.publicDomainSongDetails?.pd_composer));
            //             if(typeof dataItem?.song_licensor_id==="number"){
            //                 composersToDelete.push(dataItem?.song_pd_composer_id);
            //             }
            //             pd_composer_list?.splice(rowIndex,1);
            //             this.setState(prevState => ({
            //                 publicDomainSongDetails: {
            //                     ...prevState.publicDomainSongDetails,
            //                     pd_composer: pd_composer_list?.length>0?pd_composer_list:[]
            //                 }    
            //             }))
            //     }
            // }
            // break;
        }
    }
    getSongDetails = () => {
        this.setState({ isFetchingSongDetails: true, isLoading: true })
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/songDetails?song_id=${this.state.songId}`, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                this.setState({ isFetchingSongDetails: false, isLoading: false, isPd: response?.data[0]?.is_PD });
                if (response?.data[0]?.is_PD === 1) {
                    let songDetails = response?.data[0];
                    songDetails.pd_composer = response?.data[0]?.pd_composer?.map(item => ({
                        song_pd_composer_id: item.song_pd_composer_id,
                        pd_composer: item.pd_composer_id,
                        pd_composer_name: item.pd_composer_name,
                        year: item.year,
                        is_music: item.is_music || 0,
                        is_lyrics: item.is_lyrics || 0,
                    }))
                    this.setState({ publicDomainSongDetails: this.formatPdSong(JSON.parse(JSON.stringify({ ...songDetails }))),
                    backupComposerList: this.formatPdSong(JSON.parse(JSON.stringify({ ...songDetails }))) }, () => {
                        if (this.props?.mode === "Edit" && this.state.publicDomainSongDetails?.song_id > 0) {
                            queryParam = {
                                "songId": this.state.publicDomainSongDetails?.song_id,
                                "songName": this.state.publicDomainSongDetails?.song_title,
                                "module": "songs",
                                "subModule": "music",
                                "tenantName": this.props.userContext?.active_tenant?.tenant_name,
                                "isBoxUpload": 1,
                                "isReplaceFile": 0,
                                "replaceFileName": null
                            }
                            this.onLoadFileData(queryParam.subModule, queryParam);
                            queryParam = {
                                "songId": this.state.publicDomainSongDetails?.song_id,
                                "songName": this.state.publicDomainSongDetails?.song_title,
                                "module": "songs",
                                "subModule": "lyrics",
                                "tenantName": this.props.userContext?.active_tenant?.tenant_name,
                                "isBoxUpload": 1,
                                "isReplaceFile": 0,
                                "replaceFileName": null
                            }
                            this.onLoadFileData(queryParam.subModule, queryParam);
                        }
                    })
                } else {
                    let songDetails = response?.data[0];
                    songDetails.licensors_list = songDetails?.licensors_list?.map(item => ({
                        licensor_name: item.licensor_name,
                        licensor: item.s_licensor_id,
                        song_licensor_id: item.song_licensor_id,
                        licensor_is_active: item.licensor_is_active ? 0 : 1,
                        is_SM: item.is_SM || 0,
                        licensor_share: item.licensor_share,
                        cue_sheet_publisher_list: item.cue_sheet_publisher_list
                    }))
                    songDetails.performers_list = songDetails?.performers_list?.map(item => ({
                        performer_name: item.performer_name,
                        performer: item.performer_id,
                        performer_is_do_not_use: item.performer_is_do_not_use || 0,
                        performer_licensor_list: item.performer_licensor_list?.map((per) => ({
                            performer_licensor_is_active: per.performer_licensor_is_active ? 0 : 1,
                            performer_licensor_id: per.performer_licensor_id,
                            performer_licensor_name: per.performer_licensor_name,
                            performer_licensor_share: per.performer_licensor_share,
                            performer_licensor_is_do_not_use: per.performer_licensor_is_do_not_use,
                            p_licensor_id: per.p_licensor_id
                        })),
                        song_performer_id: item.song_performer_id
                    }))
                    this.setState({
                        backupLicensorList: songDetails?.licensors_list,
                        selectedLicensor: songDetails?.licensors_list?.[0],
                        backupPerformerList: songDetails?.performers_list,
                        selectedPerformer: songDetails?.performers_list?.[0],
                        cueSheetPublisherList: this.formatCueSheetPublisherList(songDetails?.licensors_list?.[0]?.cue_sheet_publisher_list) || [],
                        performerLicensorList: this.formatPerfLicensorList(songDetails?.performers_list?.[0]?.performer_licensor_list) || [],
                        songDetails: this.formatSong(JSON.parse(JSON.stringify({ ...songDetails })))
                    }, () => {
                        if (this.props?.mode === "Edit" && this.state.songDetails?.song_id > 0) {
                            queryParam = {
                                "songId": this.state.songDetails?.song_id,
                                "songName": this.state.songDetails?.song_title,
                                "module": "songs",
                                "subModule": "music",
                                "tenantName": this.props.userContext?.active_tenant?.tenant_name,
                                "isBoxUpload": 1,
                                "isReplaceFile": 0,
                                "replaceFileName": null
                            }
                            this.onLoadFileData(queryParam.subModule, queryParam);
                            queryParam = {
                                "songId": this.state.songDetails?.song_id,
                                "songName": this.state.songDetails?.song_title,
                                "module": "songs",
                                "subModule": "lyrics",
                                "tenantName": this.props.userContext?.active_tenant?.tenant_name,
                                "isBoxUpload": 1,
                                "isReplaceFile": 0,
                                "replaceFileName": null
                            }
                            this.onLoadFileData(queryParam.subModule, queryParam);
                        }
                    })
                }
            },
                (err) => {
                    this.setState({ isLoading: false });
                    console.log("Error in fetching data", err)
                })
    }

    getStaticData = (optionType, tableName) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/staticData?staticTable=${tableName}`, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let list = [];
                if (tableName === "lu_affiliation") {
                    list = [];
                    list = response?.data?.map(item => ({
                        label: item.name,
                        value: item.id,
                        is_active: item.is_active,
                    }));
                    CueSheetHeading.headings[2].selectOptions = list
                }
                if (tableName === "lu_cue_sheet_publisher") {
                    let usedPublisherList = this.state.cueSheetPublisherList?.map(item => item.cue_sheet_publisher)
                    let unusedPublisherList = response?.data?.filter((item) => {
                        if (!usedPublisherList?.includes(item.id))
                            return item
                    })
                    list = [];
                    list = unusedPublisherList?.map(item => ({
                        text: item.name,
                        value: item.id,
                    }));
                    CueSheetHeading.headings[1].selectOptions = list
                }
                if (tableName === "lu_performer") {
                    let usedPerformerIds = this.state.songDetails?.performers_list?.map(item => item.performer)
                    let unusedPerformersList = response?.data?.filter((item) => {
                        if (!usedPerformerIds.includes(item.id))
                            return item
                    })
                    list = [];
                    list = unusedPerformersList?.map(item => ({
                        text: item.name,
                        value: item.id,
                    }));
                    PerformerHeading.headings[1].selectOptions = list;
                }
                if (tableName === "lu_public_domain_genre") {
                    list = [];
                    list = response?.data?.map(item => ({
                        label: item.name,
                        value: item.id,
                    }));
                    //PdComposers.headings[0].selectOptions = list;
                }
                this.setState({ [optionType]: list })
            })
    }

    handleListEdit = (fieldName, field, value, index) => {
        let songDetails = { ...this.state.songDetails };
        switch (fieldName) {
            case "writers_list":
                let songList = songDetails[fieldName][index];
                let song_item = {
                    ...songList,
                    [field]: value,
                };
                songDetails[fieldName][index] = song_item;
                break;
        }
        this.setState({
            songDetails: songDetails,
            postInitiated: false,
            writerMode: null,
            selectedWriterIndex: null
        });

    };

    addDivisionCallback = (listName, index) => {
        let allSongDetails = this.state.songDetails
        let songsArray = JSON.parse(JSON.stringify({ ...writersList }));
        allSongDetails.writers_list.push(songsArray)
        songsArray.index = this.state.writerIndex;
        this.setState({
            writerIndex: this.state.writerIndex + 1,
            songDetails: allSongDetails,
            postInitiated: false,
        });
    }

    handleMusicTypeListEdit = (field, value, obj, listName) => {
        let songDetails = JSON.parse(JSON.stringify({ ...this.state.publicDomainSongDetails }));
        let id = (listName === "pd_genre") ? "song_pd_genre_id" : "";
        let list = songDetails[listName]?.map(item => {
            let currentVal = item;
            if ((!obj[id] && item.index && obj.index && (item.index === obj.index)) || (item[id] && obj[id] && (item[id] === obj[id]))) {
                currentVal[field] = value;
                let musicTypeName = this.state.musicTypeOptions?.find(type => type.value === value)?.label;
                if (field === 'public_domain_genre_id') {
                    currentVal.public_domain_genre = musicTypeName;
                    currentVal.public_domain_genre_id = value;
                }
                return currentVal;
            } return currentVal;
        })
        this.handlePdSongOnchange(listName, list);
    }

    handleChange = (field, value) => {
        switch (field) {
            case 'song_title':
            case 'notes':
                this.setState(prevState => ({
                    songDetails: {
                        ...prevState.songDetails,
                        [field]: value
                    },
                }));
                this.setState(prevState => ({
                    publicDomainSongDetails: {
                        ...prevState.publicDomainSongDetails,
                        [field]: value
                    },
                }));
                break;
            case 'is_coa':
            case 'song_is_do_not_use':
                this.setState(prevState => ({
                    songDetails: {
                        ...prevState.songDetails,
                        [field]: value ? 1 : 0,
                    },
                }));
                this.setState(prevState => ({
                    publicDomainSongDetails: {
                        ...prevState.publicDomainSongDetails,
                        [field]: value ? 1 : 0,
                    },
                }));
                break;
            case 'is_arrangement':
            case 'is_all_promo_use_restricted':
            case 'is_limited_promo_use':
            case 'is_library':
            //case 'is_coa':
            case 'is_contains_simple':
            case 'is_studio_owned':    
                this.setState(prevState => ({
                    songDetails: {
                        ...prevState.songDetails,
                        [field]: value ? 1 : 0,
                    },
                }));
                break;
            case 'is_active':
                this.setState(prevState => ({
                    songDetails: {
                        ...prevState.songDetails,
                        [field]: value ? 0 : 1,
                    },
                }));
                break;
            case 'isPd':
                this.setState({ [field]: value ? 1 : 0 })
                if (this.props.proposedNewSong && value) {
                    this.setState(prevState => ({
                        publicDomainSongDetails: {
                            ...prevState.publicDomainSongDetails,
                            ['song_title']: this.props.proposedNewSong,
                        },
                    }));
                }

                break;
            default:
                this.setState(prevState => ({
                    songDetails: {
                        ...prevState.songDetails,
                        [field]: value,
                    },
                }));
                break;
        }

    };

    handlePdSongOnchange = (field, value) => {
        if (field === "song_is_do_not_use") {
            this.setState(prevState => ({
                publicDomainSongDetails: {
                    ...prevState.publicDomainSongDetails,
                    [field]: value ? 1 : 0,
                },
                postInitiated: false
            }));
        } else {
            this.setState(prevState => ({
                publicDomainSongDetails: {
                    ...prevState.publicDomainSongDetails,
                    [field]: value,
                },
                postInitiated: false
            }));
        }
    }

    formatCueSheetPublisherList = (list) => {
        let cueSheetList = list?.map(item => ({
            cue_sheet_publisher: item.cue_sheet_publisher ? item.cue_sheet_publisher : item.cue_sheet_publisher_id,
            cue_sheet_publisher_name: item.cue_sheet_publisher_name,
            affiliation: item.affiliation_id,
            cue_sheet_share: item.cue_sheet_share,
            song_cue_sheet_publisher_id: item.song_cue_sheet_publisher_id
        }))

        return cueSheetList;
    }

    formatPerfLicensorList = (list) => {
        let performerLicensorList = list?.map(item => ({
            performer_licensor_id: item.performer_licensor ? item.performer_licensor : item.performer_licensor_id,
            performer_licensor: item.p_licensor_id || item?.performer_licensor,
            performer_licensor_name: item?.performer_licensor_name,
            performer_licensor_is_active: item.performer_licensor_is_active,
            performer_licensor_share: item.performer_licensor_share,
            performer_licensor_is_do_not_use: item.performer_licensor_is_do_not_use
        }))

        return performerLicensorList;
    }

    navigationCallback = (obj, node) => {
        if (node === "licensor") {
            this.setState({ selectedLicensor: obj })
            let cueSheetList = this.formatCueSheetPublisherList(obj?.cue_sheet_publisher_list);
            this.setState({ cueSheetPublisherList: cueSheetList })
        } else {
            this.setState({ selectedPerformer: obj })
            let performerLicensorList = this.formatPerfLicensorList(obj?.performer_licensor_list)
            this.setState({ performerLicensorList: performerLicensorList })
        }
    }

    selectedWriter = index => (event, newValue) => {
        this.handleListEdit('writers_list', 'writer_id', newValue?.value || null, index)
        this.handleListEdit('writers_list', 'writer_name', newValue?.text || null, index)
        this.handleListEdit('writers_list', 'temp_writer_name', newValue?.temp_text || null, index)
        this.setState({ writersOption: [] });
    }

    addAdditionalField = (listName, index) => {
        switch (listName) {
            case 'writers_list': {
                let songDetails = this.state.songDetails;
                if (songDetails.writers_list[index].writer_name) {
                    let writersArray = JSON.parse(JSON.stringify({ ...writersList }));
                    let writers_list = [...songDetails.writers_list];
                    writersArray.index = this.state.writerIndex;
                    writers_list.push(writersArray);
                    songDetails.writers_list = writers_list;
                    this.setState({ writerIndex: this.state.writerIndex + 1, songDetails: songDetails, postInitiated: false });
                }
            }
                break;
            case 'pd_genre': {
                let songDetails = this.state.publicDomainSongDetails;
                if (songDetails.pd_genre[index].public_domain_genre) {
                    let musicTypeArray = JSON.parse(JSON.stringify({ ...musicTypes }));
                    let pd_genre = [...songDetails.pd_genre];
                    musicTypeArray.index = this.state.musicTypeIndex;
                    pd_genre.push(musicTypeArray);
                    songDetails.pd_genre = pd_genre;
                    this.setState({
                        musicTypeIndex: this.state.musicTypeIndex + 1,
                        publicDomainSongDetails: songDetails,
                        postInitiated: false
                    });
                }
            }
                break;
        }
    }

    removeField = (listName, callbackItem, index) => {
        switch (listName) {
            case 'writers_list': {
                let songDetails = this.state.songDetails;
                songDetails.writers_list[index].song_writer_is_delete = 1;
                if (callbackItem.writer_id) {
                    let temp = [];
                    this.state.activeWriters?.map((item) => {
                        temp.push(item);
                    });
                    let writers_list = songDetails?.writers_list?.filter(item =>
                        (item.song_writer_is_delete === 1 && item.index === callbackItem.index)
                    );
                    temp.push(writers_list[0]);
                    this.setState({ activeWriters: temp })
                }
                let writers_list = songDetails.writers_list.filter(item =>
                    (item.song_writer_id !== callbackItem.song_writer_id) || (item.index !== callbackItem.index)
                );
                if (writers_list.length === 0) {
                    songDetails.writers_list = [{ ...writersList }];
                } else {
                    songDetails.writers_list = writers_list;
                }
                this.setState({
                    songDetails: songDetails, listName: null, callbackItem: null, postInitiated: false
                });
            }
                break;
            case 'pd_genre': {
                let songDetails = this.state.publicDomainSongDetails;
                songDetails.pd_genre[index].pd_genre_is_delete = 1;
                if (callbackItem.public_domain_genre_id) {
                    let temp = [];
                    this.state.activeMusicTypes?.map((item) => {
                        temp.push(item);
                    });
                    let pd_genre = songDetails?.pd_genre?.filter(item =>
                        (item.pd_genre_is_delete === 1 && item.index === callbackItem.index)
                    );
                    temp.push(pd_genre[0]);
                    this.setState({ activeMusicTypes: temp })
                }
                let pd_genre = songDetails.pd_genre.filter(item =>
                    (item.song_pd_genre_id !== callbackItem.song_pd_genre_id) || (item.index !== callbackItem.index)
                );
                if (pd_genre.length === 0) {
                    songDetails.pd_genre = [{ ...musicTypes }];
                } else {
                    songDetails.pd_genre = pd_genre;
                }
                this.setState({
                    publicDomainSongDetails: songDetails, listName: null, callbackItem: null, postInitiated: false
                });
            }
                break;
        }
    }

    getEntity = (entity, entitytype, searchString, identifier = null) => {
        if (this.state.searchCancelToken != null)
            this.state.searchCancelToken.cancel("Operation canceled due to new request");
        var cancelToken = axios.CancelToken.source();
        this.setState({ searchCancelToken: cancelToken });

        ClearTrackService.getDataWithCancel(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entitytype}&searchString=${searchString}`, cancelToken, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                switch (entitytype) {
                    case 'LICENSOR':
                        if (identifier === "Sync-Licensors") {
                            let usedLicensorIds = this.state.songDetails?.licensors_list?.map(item => item.licensor)
                            let unusedLicensorList = response?.data?.filter((item) => {
                                if (!usedLicensorIds.includes(item.licensor_id))
                                    return item
                            })
                            let list = unusedLicensorList?.map(item => ({
                                text: item.licensor_name,
                                value: item.licensor_id,
                                // licensor_is_active: item.is_active === 1 ? false : true
                            }));
                            if (searchString.length > 0) {
                                LicenseHeading.headings[1].selectOptions = list;
                                this.setState({ [entity]: list });
                            } else {
                                LicenseHeading.headings[1].selectOptions = [];
                                this.setState({
                                    [entity]: []
                                });
                            }
                        } else {
                            let usedLicensorIds = this.state.performerLicensorList?.map(item => item.performer_licensor)
                            let unusedLicensorList = response?.data?.filter((item) => {
                                if (!usedLicensorIds.includes(item.licensor_id))
                                    return item
                            })
                            let list2 = unusedLicensorList?.map(item => ({
                                text: item.licensor_name,
                                value: item.licensor_id,
                            }));
                            if (searchString.length > 0) {
                                LicensorsWayneHeading.headings[1].selectOptions = list2;
                                this.setState({ [entity]: list2 });
                            } else {
                                LicensorsWayneHeading.headings[1].selectOptions = [];
                                this.setState({
                                    [entity]: []
                                });
                            }
                        }
                        break;
                    case 'PD_COMPOSER':
                        let usedPDComposer = this.state.publicDomainSongDetails?.pd_composer?.map(item => item.pd_composer)
                        let unusedPDComposerList = response?.data?.filter((item) => {
                            if (!usedPDComposer.includes(item.pd_composer_id))
                                return item
                        })
                        this.state.publicDomainSongDetails?.pd_composer
                        let composers = unusedPDComposerList?.map(item => ({
                            text: item.pd_composer_name,
                            value: item.pd_composer_id,
                        }));
                        PdComposers.headings[0].selectOptions = composers;
                        this.setState({ [entity]: composers });
                        break;
                    case 'WRITER':
                        let writers = response?.data?.map(item => ({
                            text: `${item.writer_name ? item?.writer_name : ''}${item.affiliation_name ? ' (' + item.affiliation_name + ')' : ''} ${(item.affiliation_name && item?.share != null && item?.share !== 0) ? item?.share : ''}`,
                            temp_text: `${item.writer_name ? item?.writer_name : ''}${item.affiliation_name ? '-' + item.affiliation_name : ''} ${(item.affiliation_name && item?.share != null && item?.share !== 0) ? item?.share : ''}`,
                            value: item.writer_id,
                        }));
                        this.setState({ [entity]: writers })
                        break;
                }
            })

    }

    handleAddNewItem = (tableName, newDataItem) => {
        // this.setState({MandatorySyncLicensorsError:false,MandatoryPerformersError:false,MandatoryCueSheetPublishersError:false,MandatoryLicensorsWayne:false},()=>{


        let songDetails = { ...this.state.songDetails }
        let licensors_list_temp= JSON.parse(JSON.stringify(this.state.songDetails?.licensors_list));
        if (!newDataItem || Object.entries(newDataItem).length === 0) {
            return;
        }
        let newDataItemTemp;
        switch (tableName) {
            case 'Sync-Licensors':
                let licensorItem = this.state.licensors?.find(o => o.value === newDataItem?.licensor?.value);
                newDataItemTemp = {
                    "licensor": newDataItem?.licensor ? newDataItem?.licensor?.value : null,
                    "licensor_name": newDataItem?.licensor ? newDataItem?.licensor?.text : null,
                    "licensor_is_active": newDataItem?.licensor_is_active ? (newDataItem?.licensor_is_active ? 1 : 0) : 0,
                    "licensor_share": newDataItem?.licensor_share ? newDataItem?.licensor_share : null,
                    "is_SM": newDataItem?.is_SM ? newDataItem?.is_SM === true ? 1 : 0 : 0,
                    "song_licensor_id": 'new' + Date.now(),
                    "cue_sheet_publisher_list": []
                }
                // if ((newDataItem?.licensor_share !== null && newDataItem?.licensor_share !== undefined && validateNumDotOnly(newDataItem?.licensor_share) && parseFloat(newDataItem?.licensor_share) != null) && (newDataItem?.licensor?.value !== null && newDataItem?.licensor?.value !== undefined)) {
                licensors_list_temp?.push(newDataItemTemp);
                this.setState((prevState) => ({
                    songDetails: {
                        ...prevState.songDetails,
                        ["licensors_list"]: licensors_list_temp
                    },
                    MandatorySyncLicensorsError: false
                }), () => {
                    this.navigationCallback(newDataItemTemp, 'licensor')
                });
                LicenseHeading.headings[1].selectOptions = [];
                // } else {
                // this.setState((prevState) => ({
                //     MandatorySyncLicensorsError: true
                // }));
                // this.setState({ MandatorySyncLicensorsError: true})
                // }
                break;
            case 'Cue-Sheet-Publishers':
                // let initialLicensor = {};
                // if (this.state.songDetails?.licensors_list?.length > 0) {
                //     initialLicensor = this.state.songDetails?.licensors_list[0];
                //     this.setState({ selectedLicensor: initialLicensor })
                // }
                let affiliationItem = newDataItem?.affiliation ? this.state.affilationOptions?.find(o => o.value === newDataItem?.affiliation) : null;
                newDataItemTemp = {
                    "cue_sheet_publisher_name": newDataItem?.cue_sheet_publisher ? newDataItem?.cue_sheet_publisher?.text ? newDataItem?.cue_sheet_publisher?.text : null : null,
                    "cue_sheet_publisher": newDataItem?.cue_sheet_publisher ? newDataItem?.cue_sheet_publisher?.value ? newDataItem?.cue_sheet_publisher?.value : null : null,
                    "affiliation_name": affiliationItem?.label ? affiliationItem?.label : null,
                    "affiliation_id": affiliationItem?.value ? affiliationItem?.value : null,
                    "cue_sheet_share": newDataItem?.cue_sheet_share ? newDataItem?.cue_sheet_share : null,
                    "song_cue_sheet_publisher_id": 'new' + Date.now()
                }
                // if (newDataItem?.cue_sheet_publisher && newDataItem?.affiliation && newDataItem?.cue_sheet_share && validateNumDotOnly(newDataItem?.cue_sheet_share) ) {

                let selectedLicensor = this.state.selectedLicensor ? this.state.selectedLicensor : this.state.songDetails?.licensors_list[0];
                songDetails?.licensors_list?.map(item => {
                    if (item.song_licensor_id === selectedLicensor?.song_licensor_id &&
                        item.licensor === selectedLicensor?.licensor
                    ) {
                        item?.cue_sheet_publisher_list?.push(newDataItemTemp)
                        this.setState({
                            cueSheetPublisherList: item?.cue_sheet_publisher_list?.map(item => ({
                                cue_sheet_publisher: item?.cue_sheet_publisher_id || item?.cue_sheet_publisher,
                                cue_sheet_publisher_name: item?.cue_sheet_publisher_name,
                                affiliation: item?.affiliation_id || item?.affiliation,
                                cue_sheet_share: item?.cue_sheet_share,
                                song_cue_sheet_publisher_id: item?.song_cue_sheet_publisher_id
                            }))
                        })
                    }
                })
                this.setState({ songDetails: songDetails, MandatoryCueSheetPublishersError: false })
                CueSheetHeading.headings[1].selectOptions = []

                // } else {
                //     this.setState({ MandatoryCueSheetPublishersError: true })
                // }
                break;
            case 'performers':
                newDataItemTemp = {
                    "performer_name": newDataItem?.performer?.text,
                    "performer": newDataItem?.performer?.value,
                    "performer_is_do_not_use": newDataItem?.performer_is_do_not_use === true ? 1 : 0,
                    "performer_licensor_list": [],
                    "song_performer_id": 'new' + Date.now()
                }
                if (newDataItem?.performer !== null && newDataItem?.performer !== undefined) {
                    songDetails?.performers_list?.push(newDataItemTemp)
                    this.setState({ songDetails: songDetails, MandatoryPerformersError: false }, () => {
                        this.navigationCallback(newDataItemTemp, 'performer')
                    })
                    PerformerHeading.headings[1].selectOptions = [];
                } else {
                    this.setState({ MandatoryPerformersError: true })
                }
                break;
            case 'Licensors-Wayne':
                //let initialPerformer = {};
                // if (this.state.songDetails?.performers_list?.length > 0) {
                //     initialPerformer = this.state.songDetails?.performers_list[0];
                //     this.setState({ selectedPerformer: initialPerformer })
                // }
                newDataItemTemp = {
                    "performer_licensor_id": 'new' + Date.now(),
                    "performer_licensor_name": newDataItem?.performer_licensor ? newDataItem?.performer_licensor?.text : null,
                    "performer_licensor": newDataItem?.performer_licensor ? newDataItem?.performer_licensor?.value : null,
                    "performer_licensor_is_active": newDataItem?.performer_licensor_is_active ? (newDataItem?.performer_licensor_is_active ? 1 : 0) : 0,
                    "performer_licensor_share": newDataItem?.performer_licensor_share ? newDataItem?.performer_licensor_share : null,
                    "performer_licensor_is_do_not_use": newDataItem?.performer_licensor_is_do_not_use ? newDataItem?.performer_licensor_is_do_not_use === true ? 1 : 0 : 0,
                }

                // if (newDataItem?.performer_licensor && newDataItem?.performer_licensor_share && validateNumDotOnly(newDataItem?.performer_licensor_share)) {
                let selectedPerformer = this.state.selectedPerformer ? this.state.selectedPerformer : this.state.songDetails?.performers_list[0];
                songDetails?.performers_list?.map(item => {
                    if (item?.song_performer_id === selectedPerformer?.song_performer_id &&
                        item?.performer === selectedPerformer?.performer
                    ) {
                        item?.performer_licensor_list?.push(newDataItemTemp)
                        this.setState({
                            performerLicensorList: item?.performer_licensor_list?.map(item => ({
                                performer_licensor_id: item?.performer_licensor_id,
                                performer_licensor: item?.p_licensor_id || item?.performer_licensor,
                                performer_licensor_name: item?.performer_licensor_name,
                                performer_licensor_is_active: item?.performer_licensor_is_active,
                                performer_licensor_share: item?.performer_licensor_share,
                                performer_licensor_is_do_not_use: item?.performer_licensor_is_do_not_use
                            })), editingLicensorsWayne: false, MandatoryLicensorsWayne: false
                        })
                    }
                })
                this.setState({ songDetails: songDetails })
                LicensorsWayneHeading.headings[1].selectOptions = [];
                // } else {
                //     this.setState({ MandatoryLicensorsWayne: true })
                // }
                break;
            case 'pd-composers':
                let pdSongDetails = this.state.publicDomainSongDetails;
                newDataItem = {
                    "pd_composer": newDataItem?.pd_composer.value,
                    "pd_composer_name": newDataItem?.pd_composer.text,
                    "year": newDataItem?.year,
                    "is_music": newDataItem?.is_music === true ? 1 : 0,
                    "is_lyrics": newDataItem?.is_lyrics === true ? 1 : 0,
                    "song_pd_composer_id": null,
                }
                pdSongDetails?.pd_composer?.push(newDataItem)
                this.setState({ publicDomainSongDetails: pdSongDetails })
                PdComposers.headings[1].selectOptions = [];
                break;
        }
        //  })
    }

    saveInlineEdits = (tableName, dataItem, index, field, newDataItem) => {
        let songDetails = JSON.parse(JSON.stringify(this.state.songDetails));
        switch (tableName) {
            case 'Sync-Licensors': {
                //postObj = JSON.parse(JSON.stringify(this.state.initialRecordData));
                //songDetails.licensors_list = [...dataItem];
                let updatedItem = { ...dataItem[index] };
                if (updatedItem.licensor_share && validateNumDotOnly(updatedItem.licensor_share) && updatedItem?.licensor?.value) {
                    songDetails.licensors_list[index].licensor_name = updatedItem?.licensor ? updatedItem?.licensor?.text ? updatedItem?.licensor?.text : null : null;
                    songDetails.licensors_list[index].licensor = updatedItem?.licensor ? updatedItem?.licensor?.value ? updatedItem?.licensor?.value : null : null;
                    songDetails.licensors_list[index].is_SM = updatedItem?.is_SM ? (updatedItem?.is_SM === true || updatedItem?.is_SM === 1) ? 1 : 0 : 0;
                    songDetails.licensors_list[index].licensor_is_active = updatedItem?.licensor_is_active ? (updatedItem?.licensor_is_active === true || updatedItem?.licensor_is_active === 1) ? 1 : 0 : 0;
                    songDetails.licensors_list[index].cue_sheet_publisher_list = updatedItem?.cue_sheet_publisher_list ? updatedItem?.cue_sheet_publisher_list : null;
                    songDetails.licensors_list[index].licensor_share = updatedItem?.licensor_share ? updatedItem?.licensor_share : null;

                    songDetails.licensors_list[index]['editing'] = false;
                    this.setState({ songDetails: songDetails, editingSyncLicensors: false, MandatorySyncLicensorsError: false },()=>{
                        this.setState({backupLicensorList:this.state.songDetails?.licensors_list})
                    })
                    LicenseHeading.headings[1].selectOptions = [];
                } else {
                    songDetails.licensors_list[index]['editing'] = true;
                    this.setState({ editingSyncLicensors: true, MandatorySyncLicensorsError: true })
                }
                break;
            }
            case 'Cue-Sheet-Publishers': {
                songDetails?.licensors_list?.map(item => {
                    if (item.song_licensor_id === this.state.selectedLicensor?.song_licensor_id &&
                        item.licensor === this.state.selectedLicensor?.licensor
                    ) {
                        item.cue_sheet_publisher_list = [...dataItem];
                        if (dataItem[index]?.cue_sheet_publisher && dataItem[index]?.affiliation && dataItem[index]?.cue_sheet_share && validateNumDotOnly(dataItem[index]?.cue_sheet_share)) {
                            item.cue_sheet_publisher_list[index].cue_sheet_publisher_name = item.cue_sheet_publisher_list[index]?.cue_sheet_publisher?.text;
                            item.cue_sheet_publisher_list[index].cue_sheet_publisher = item.cue_sheet_publisher_list[index]?.cue_sheet_publisher?.value;
                            item.cue_sheet_publisher_list[index]['editing'] = false;
                            this.setState({
                                cueSheetPublisherList: item?.cue_sheet_publisher_list, editingCueSheetPublishers: false, MandatoryCueSheetPublishersError: false
                            })
                        } else {
                            this.setState({
                                editingCueSheetPublishers: true, MandatoryCueSheetPublishersError: true
                            })
                        }
                    }
                })
                this.setState({ songDetails: songDetails })
                CueSheetHeading.headings[1].selectOptions = []
                break;
            }
            case 'performers': {
                let updatedItem = { ...dataItem[index] };
                if (dataItem[index].performer) {
                    songDetails.performers_list[index].performer_name = updatedItem?.performer?.text;
                    songDetails.performers_list[index].performer = updatedItem?.performer?.value;
                    songDetails.performers_list[index].performer_is_do_not_use = (updatedItem?.performer_is_do_not_use === true ||
                        updatedItem?.performer_is_do_not_use === 1) ? 1 : 0;
                    songDetails.performer_licensor_list = updatedItem?.performer_licensor_list;
                    songDetails.performers_list[index]['editing'] = false;
                    this.setState({ songDetails: songDetails, editingPerformers: false, MandatoryPerformersError: false },()=>{
                        this.setState({backupPerformerList: songDetails?.performers_list})
                    })
                    PerformerHeading.headings[1].selectOptions = [];
                } else {
                    songDetails.performers_list[index]['editing'] = true;
                    this.setState({ editingPerformers: true, MandatoryPerformersError: true })
                }
                break;
            }
            case 'Licensors-Wayne': {
                songDetails?.performers_list?.map(item => {
                    if (item.song_performer_id === this.state.selectedPerformer?.song_performer_id &&
                        item.performer === this.state.selectedPerformer?.performer
                    ) {
                        let updatedItem = { ...dataItem[index] };

                        if (updatedItem.performer_licensor && updatedItem?.performer_licensor_share && validateNumDotOnly(updatedItem?.performer_licensor_share)) {
                            item.performer_licensor_list[index].performer_licensor_name = updatedItem?.performer_licensor?.text;
                            item.performer_licensor_list[index].performer_licensor = updatedItem?.performer_licensor?.value;
                            item.performer_licensor_list[index].performer_licensor_share = updatedItem?.performer_licensor_share;
                            item.performer_licensor_list[index].performer_licensor_is_active = (updatedItem?.performer_licensor_is_active === true ||
                                updatedItem.performer_licensor_is_active === 1) ? 1 : 0;
                            item.performer_licensor_list[index].performer_licensor_is_do_not_use = (updatedItem?.performer_licensor_is_do_not_use === true ||
                                updatedItem.performer_licensor_is_do_not_use === 1) ? 1 : 0;
                            item.performer_licensor_list[index]['editing'] = false;
                            this.setState({
                                performerLicensorList: item?.performer_licensor_list, editingLicensorsWayne: false, MandatoryLicensorsWayne: false
                            })
                        } else {
                            this.setState({
                                editingLicensorsWayne: true, MandatoryLicensorsWayne: true
                            })
                        }
                    }
                })
                this.setState({ songDetails: songDetails })
                LicensorsWayneHeading.headings[1].selectOptions = [];
                break;
            }
            case 'pd-composers': {
                let pdSongDetails = this.state.publicDomainSongDetails;
                let updatedItem = dataItem[index]
                if (updatedItem?.pd_composer?.value && updatedItem?.year && validateNumbersOnly(updatedItem?.year)) {

                    pdSongDetails.pd_composer[index].pd_composer_name = updatedItem?.pd_composer.text;
                    pdSongDetails.pd_composer[index].pd_composer = updatedItem?.pd_composer.value;
                    pdSongDetails.pd_composer[index].year = updatedItem?.year;
                    pdSongDetails.pd_composer[index].is_music = (updatedItem?.is_music === true || updatedItem?.is_music === 1) ? 1 : 0;
                    pdSongDetails.pd_composer[index].is_lyrics = (updatedItem?.is_lyrics === true || updatedItem?.is_lyrics === 1) ? 1 : 0;
                    pdSongDetails.pd_composer[index]['editing'] = false;
                    this.setState({ publicDomainSongDetails: pdSongDetails, editingPdComposers: false, MandatoryPdComposerError: false },()=>{
                        backupComposerList: this.state.publicDomainSongDetails?.pd_composer
                    })
                    PdComposers.headings[1].selectOptions = [];
                }
                else {
                    this.setState({
                        editingPdComposers: true, MandatoryPdComposerError: true
                    })
                }
                break;
            }
        }
    }

    handleReadCallback = (tableName, index) => {
        let songDetails = { ...this.state.songDetails };
        switch (tableName) {
            case 'Sync-Licensors':
                songDetails.licensors_list[index].editing = false;
                this.setState({ songDetails: songDetails, editingSyncLicensors: false, MandatorySyncLicensorsError: false });
                break;
            case 'Cue-Sheet-Publishers':
                let cueSheetPublisherList = [...this.state.cueSheetPublisherList];
                cueSheetPublisherList[index].editing = false;
                this.setState({ cueSheetPublisherList: cueSheetPublisherList, editingCueSheetPublishers: false, MandatoryCueSheetPublishersError: false });
                break;
            case 'performers':
                songDetails.performers_list[index].editing = false;
                this.setState({ songDetails: songDetails, editingPerformers: false, MandatoryPerformersError: false });
                break;
            case 'Licensors-Wayne':
                let performerLicensorList = [...this.state.performerLicensorList];
                performerLicensorList[index].editing = false;
                this.setState({ performerLicensorList: performerLicensorList, editingLicensorsWayne: false, MandatoryLicensorsWayne: false });
                break;
            case 'pd-composers':
                let pdSongDetails = this.state.publicDomainSongDetails;
                let updatedItem = this.state.publicDomainSongDetails.pd_composer[index];
                pdSongDetails.pd_composer[index].pd_composer_name = updatedItem?.pd_composer.text;
                pdSongDetails.pd_composer[index].pd_composer = updatedItem?.pd_composer.value;
                pdSongDetails.pd_composer[index].year = updatedItem?.year;
                pdSongDetails.pd_composer[index].is_music = (updatedItem?.is_music === true || updatedItem?.is_music === 1) ? 1 : 0;
                pdSongDetails.pd_composer[index].is_lyrics = (updatedItem?.is_lyrics === true || updatedItem?.is_lyrics === 1) ? 1 : 0;
                pdSongDetails.pd_composer[index]['editing'] = false;
                this.setState({ publicDomainSongDetails: pdSongDetails, editingPdComposers: false })
                PdComposers.headings[0].selectOptions = [];
                break;
        }
    }

    formatSongDetails = (songPostJson) => {
        let writerTemp = [];
        let musicTypeTemp = [];
        if (this.state.isPd === 1) {
            songPostJson?.pd_composer?.map((item) => {
                item['pd_composer_id'] = item?.pd_composer?.hasOwnProperty('value') ? item?.pd_composer?.value : item?.pd_composer;
                delete item.pd_composer;
            })
            this.state.activeMusicTypes?.map(item => {
                musicTypeTemp?.push(item);
            });
            songPostJson?.pd_genre?.map(item => {
                musicTypeTemp?.push(item);
            });
            songPostJson.pd_genre = musicTypeTemp?.filter(item => item?.public_domain_genre_id !== null);
        } else {
            songPostJson?.licensors_list?.map((item) => {
                item['s_licensor_id'] = item?.licensor?.hasOwnProperty('value') ? item?.licensor?.value : item?.licensor
                item['song_licensor_id'] = isNaN(item?.song_licensor_id) ? null : item?.song_licensor_id
                delete item.licensor;
                item?.cue_sheet_publisher_list?.map?.((cueSheetItem) => {
                    cueSheetItem.song_cue_sheet_publisher_id = isNaN(cueSheetItem?.song_cue_sheet_publisher_id) ? null : cueSheetItem?.song_cue_sheet_publisher_id
                    cueSheetItem['cue_sheet_publisher_id'] = cueSheetItem?.cue_sheet_publisher ?
                        cueSheetItem?.cue_sheet_publisher?.hasOwnProperty('value') ? cueSheetItem?.cue_sheet_publisher?.value : cueSheetItem?.cue_sheet_publisher : cueSheetItem?.cue_sheet_publisher_id;
                    if (!cueSheetItem?.affiliation_name) {
                        cueSheetItem['affiliation_id'] = cueSheetItem?.affiliation;
                        cueSheetItem['affiliation_name'] = this.state.affilationOptions?.find(affItem => affItem.value === cueSheetItem?.affiliation)?.label;
                    }
                    delete cueSheetItem?.cue_sheet_publisher;
                    delete cueSheetItem?.affiliation;
                })
            })
            songPostJson?.performers_list?.map((item) => {
                item['performer_id'] = item.performer?.hasOwnProperty('value') ? item?.performer?.value : item?.performer;
                item.song_performer_id = isNaN(item?.song_performer_id) ? null : item?.song_performer_id
                delete item.performer;
                item?.performer_licensor_list?.map?.((pLicensorItem) => {
                    pLicensorItem.performer_licensor_id = isNaN(pLicensorItem?.performer_licensor_id) ? null : pLicensorItem?.performer_licensor_id
                    pLicensorItem['p_licensor_id'] = pLicensorItem.performer_licensor ?
                        pLicensorItem.performer_licensor?.hasOwnProperty('value') ? pLicensorItem.performer_licensor?.value : pLicensorItem.performer_licensor : pLicensorItem.p_licensor_id;
                    delete pLicensorItem?.performer_licensor;
                })
            })
            this.state.activeWriters?.map(item => {
                writerTemp?.push(item);
            });
            songPostJson?.writers_list?.map(item => {
                let reverseFirstPart = item.writer_name?.split(' ').reverse()[0];
                let share = reverseFirstPart;
                if (share) {
                    item.share_percentage = parseFloat(share);
                } else if (share === "") {
                    item.share_percentage = 0.00;
                }
                writerTemp?.push(item);
            });

            songPostJson.writers_list = writerTemp?.filter(item => item?.writer_id !== null);

            songPostJson?.licensors_list?.map((item) => {
                item.licensor_is_active = item.licensor_is_active == 1 ? 0 : 1
            })
            songPostJson?.performers_list?.map((item) => {
                item.performer_licensor_list.map((per) => {
                    per.performer_licensor_is_active = per.performer_licensor_is_active == 1 ? 0 : 1
                })
            })
        }
        return songPostJson;
    }

    canSubmit = (songPostJson) => {
        let canSubmit = false;
        if (this.state.isPd === 0) {
            canSubmit = (songPostJson?.song_title && this.state.showMusicFileError === false && this.state.showLyricsFileError === false)
        } else {
            canSubmit = (songPostJson?.song_title && this.state.showMusicFileError === false && this.state.showLyricsFileError === false)
        }

        return canSubmit;
    }

    handleSyncLicModal = (node, dataItem, index) => (e) => {
        this.setState({ modalIndex: index, openSyncLicensorModal: dataItem?.licensor?.hasOwnProperty('value') ? dataItem?.licensor?.value : dataItem?.licensor })
    }

    handleCueSheetPubModal = (node, dataItem, index) => (e) => {
        this.setState({ modalIndex: index, openCueSheetPubModal: dataItem?.cue_sheet_publisher?.hasOwnProperty('value') ? dataItem?.cue_sheet_publisher?.value : dataItem?.cue_sheet_publisher })
    }

    handlePerformerModal = (node, dataItem, index) => (e) => {
        this.setState({ modalIndex: index, openPerformerModal: dataItem?.performer?.hasOwnProperty('value') ? dataItem?.performer?.value : dataItem?.performer })
    }

    handlePerformerLicModal = (node, dataItem, index) => (e) => {
        this.setState({ modalIndex: index, openPerformerLicModal: dataItem?.performer?.hasOwnProperty('value') ? dataItem?.performer_licensor?.value : dataItem?.performer_licensor })
    }

    // handleSubmit = () => {
    //     let postJson;
    //     if (this.state.isPd === 0) {
    //         postJson = JSON.parse(JSON.stringify(this.state.songDetails));
    //     } else {
    //         postJson = JSON.parse(JSON.stringify(this.state.publicDomainSongDetails));
    //     }
    //     this.setState({ postInitiated: true });
    //     postJson = this.formatSongDetails(postJson);
    //     postJson.is_PD = this.state.isPd;
    //     if (this.canSubmit(postJson)) {
    //         this.setState({ isSubmit: true })
    //         ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/songDetails`,
    //             postJson, this.props?.userContext?.active_tenant?.tenant_id)
    //             .then(
    //                 (response) => {
    //                     if (response.data.error) {
    //                         this.setState({ isSubmit: false, postInitiated: false });
    //                         this.props?.notificationComponent(true, "fail");
    //                     } else {
    //                         this.setState({ postInitiated: false }, () => {
    //                             this.props?.notificationComponent(true, "success");
    //                             if (response?.data?.length > 0) {
    //                                 if ((this.state.filesArr.length > 0 && this.state.filesArr[0].url.length === 0) || (this.state.filesArr1.length > 0 && this.state.filesArr1[0].url.length === 0) || (this.state.filesArr.length === 0 && this.state.deleteFilesForMusic !== null) || (this.state.filesArr.length > 0 && this.state.deleteFilesForMusic !== null) || (this.state.filesArr.length === 0 && fileToReplaceMusicBackup !== null) || (this.state.filesArr.length > 0 && fileToReplaceMusicBackup !== null) || (this.state.filesArr1.length === 0 && this.state.deleteFilesForLyrics !== null) || (this.state.filesArr1.length > 0 && this.state.deleteFilesForLyrics !== null) || (this.state.filesArr1.length === 0 && fileToReplaceLyricsBackup !== null) || (this.state.filesArr1.length > 0 && fileToReplaceLyricsBackup !== null)) {
    //                                     if (this.state.filesArr.length > 0 && this.state.filesArr[0].url.length === 0) {
    //                                         this.uploadFileAPICallForMusic(response?.data[0].song_id);
    //                                     }
    //                                     if (this.state.filesArr1.length > 0 && this.state.filesArr1[0].url.length === 0) {
    //                                         this.uploadFileAPICallForlyrics(response?.data[0].song_id);
    //                                     }
    //                                     if ((this.state.filesArr.length === 0 && this.state.deleteFilesForMusic !== null) || (this.state.filesArr.length > 0 && this.state.deleteFilesForMusic !== null) || (this.state.filesArr.length === 0 && fileToReplaceMusicBackup !== null) || (this.state.filesArr.length > 0 && fileToReplaceMusicBackup !== null)) {
    //                                         this.deleteAPICall("music");
    //                                     }
    //                                     if ((this.state.filesArr1.length === 0 && this.state.deleteFilesForLyrics !== null) || (this.state.filesArr1.length > 0 && this.state.deleteFilesForLyrics !== null) || (this.state.filesArr1.length === 0 && fileToReplaceLyricsBackup !== null) || (this.state.filesArr1.length > 0 && fileToReplaceLyricsBackup !== null)) {
    //                                         this.deleteAPICall("lyrics");
    //                                     }
    //                                 } else {
    //                                     this.setState({ isSubmit: false })
    //                                 }
    //                             }
    //                         });
    //                         if (this.props?.isMainTitle) {
    //                             this.props?.toggleRefreshMainTitleLicensor()
    //                         } else {
    //                             this.props?.toggleRefreshPage('EpisodeClearance');
    //                         }
    //                     }
    //                 },
    //                 (err) => {
    //                     this.setState({ isSubmit: false, postInitiated: false });
    //                     this.props?.notificationComponent(true, "fail");
    //                 }
    //             )
    //     }
    // };
    onConfirm = () => {
        this.deleteSongRecord();
        this.setState({showError: false})
    }

    deleteSongRecord = ()=> {
        this.setState({deleteSongRecordLoading: true})
        clearTrackService.deleteParam(Constants.ClearTrackServiceBaseUrl + `/songDetails?song_id=${this.state.songDetails?.song_id}`,
            this.props?.userContext?.active_tenant?.tenant_id)
            .then((response) => {
                this.setState({deleteSongRecordLoading: false})
                if (response.data.error) {
                    this.props?.notificationComponent(true, "fail");
                }
                else {
                    this.props?.notificationComponent(true, "success");
                }
            },
            (err) => {
                console.error("Error in fetching data",err)
                this.setState({deleteSongRecordLoading: false})
                this.props?.notificationComponent(true, "fail");
            })
    }
    handleSubmit = (payload) => {
        let postJson = JSON.parse(JSON.stringify(payload));
        if (this.state.isPd === 0) {
            if(licensorsToDelete?.length>0){
                licensorsToDelete?.map((item1)=>{
                    this.state.backupLicensorList?.map((item2)=>{
                        if(item1===item2.song_licensor_id){
                            item2["is_delete"]=1;
                            postJson?.licensors_list?.push(item2);
                        }
                    })
                })
                
            }
            
            // if(composersToDelete?.length>0){
            //     composersToDelete?.map((item1)=>{
            //         this.state.backupComposerList?.map((item2)=>{
            //             if(item1===item2.song_licensor_id){
            //                 item2["is_delete"]=1;
            //                 postJson?.licensors_list?.push(item2);
            //             }
            //         })
            //     })
                
            // }
            if(cuesheetPublisherToDelete?.length>0){
                cuesheetPublisherToDelete?.map((item1)=>{
                    this.state.songDetails?.licensors_list?.map((item2,index)=>{
                        if(item2?.song_licensor_id===item1?.song_licensor_id){
                            item1.cue_sheet_publisher["is_delete"]=1;
                            postJson?.licensors_list[index].cue_sheet_publisher_list.push(item1?.cue_sheet_publisher);
                        }
                    
                    })
                })
            }
            if(performersToDelete?.length>0){
                performersToDelete?.map((item1)=>{
                    this.state.backupPerformerList?.map((item2)=>{
                        if(item1===item2.song_performer_id){
                            item2["is_delete"]=1;
                            postJson?.performers_list?.push(item2);
                        }
                    })
                })
                
            }
            if(performerLicensorToDelete?.length>0){
                performerLicensorToDelete?.map((item1)=>{
                    this.state.songDetails?.performers_list?.map((item2,index)=>{
                        if(item2?.song_performer_id===item1?.song_performer_id){
                            item1.performer_licensor["is_delete"]=1;
                            postJson?.performers_list[index].performer_licensor_list.push(item1?.performer_licensor);
                        }
                    
                    })
                })
            }
        }
        this.setState({ postInitiated: true });
        postJson = this.formatSongDetails(postJson);
        postJson.is_PD = this.state.isPd;
        if (this.canSubmit(postJson)) {
            this.setState({ isSubmit: true })
            ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/songDetails`,
                postJson, this.props?.userContext?.active_tenant?.tenant_id)
                .then(
                    (response) => {
                        if (response.data.error) {
                            this.setState({ isSubmit: false, postInitiated: false });
                            this.props?.notificationComponent(true, "fail");
                        } else {
                            licensorsToDelete=[],cuesheetPublisherToDelete=[],performersToDelete=[],performerLicensorToDelete=[];
                            let songIdForUpload = response?.data[0].song_id;
                            let songTitle = postJson?.song_title;
                            if ((this.state.filesArr.length > 0 && this.state.filesArr[0].url.length === 0) ||
                                (this.state.filesArr1.length > 0 && this.state.filesArr1[0].url.length === 0) ||
                                (this.state.filesArr.length === 0 && this.state.deleteFilesForMusic !== null) ||
                                (this.state.filesArr.length > 0 && this.state.deleteFilesForMusic !== null) ||
                                (this.state.filesArr.length === 0 && fileToReplaceMusicBackup !== null) ||
                                (this.state.filesArr.length > 0 && fileToReplaceMusicBackup !== null) ||
                                (this.state.filesArr1.length === 0 && this.state.deleteFilesForLyrics !== null) ||
                                (this.state.filesArr1.length > 0 && this.state.deleteFilesForLyrics !== null) ||
                                (this.state.filesArr1.length === 0 && fileToReplaceLyricsBackup !== null) ||
                                (this.state.filesArr1.length > 0 && fileToReplaceLyricsBackup !== null)) {
                                let p1, p2, p3, p4;
                                let promiseArray = [];
                                if (this.state.filesArr.length > 0 && this.state.filesArr[0].url.length === 0) {
                                    // this.uploadFileAPICallForMusic(response?.data[0].song_id);
                                    let songId = response?.data[0].song_id;
                                    if (formDataMusic !== null) {
                                        formDataMusic.append("songId", songId);
                                    }
                                    let extensionUrl = '/uploadFile';
                                    p1 = clearTrackService.uploadFileFormData(Constants.ClearTrackServiceBaseUrl + extensionUrl, formDataMusic, this.props.userContext?.active_tenant?.tenant_id)
                                    promiseArray.push(p1)
                                } else if (this.state.filesArr1.length > 0 && this.state.filesArr1[0].url.length === 0) {
                                    // this.uploadFileAPICallForlyrics(response?.data[0].song_id);

                                    let extensionUrl = '/uploadFile';
                                    //let fileData = [...this.state.fileLyricsData];
                                    //fileData.push(["songId", "" + songId + ""]);
                                    let songId = response?.data[0].song_id
                                    if (formDataLyrics !== null) {
                                        formDataLyrics.append("songId", songId);
                                    }
                                    p2 = clearTrackService.uploadFileFormData(Constants.ClearTrackServiceBaseUrl + extensionUrl, formDataLyrics, this.props.userContext?.active_tenant?.tenant_id)
                                    promiseArray.push(p2)
                                }
                                if ((this.state.filesArr.length === 0 && this.state.deleteFilesForMusic !== null) ||
                                    (this.state.filesArr.length > 0 && this.state.deleteFilesForMusic !== null) ||
                                    (this.state.filesArr.length === 0 && fileToReplaceMusicBackup !== null) ||
                                    (this.state.filesArr.length > 0 && fileToReplaceMusicBackup !== null)) {

                                    // this.deleteAPICall("music");
                                    let queryParamObj = {
                                        "songId": this.state.songDetails?.song_id,
                                        "songName": this.state.songDetails?.song_title,
                                        "module": "songs",
                                        "subModule": "music",
                                        "tenantName": this.props.userContext?.active_tenant?.tenant_name,
                                        "tenantId": this.props.userContext?.active_tenant?.tenant_id,
                                        "isBoxUpload": 1
                                    }
                                    if (this.state.deleteFilesForMusic !== null) {
                                        queryParamObj.fileName = this.state.deleteFilesForMusic;
                                    }
                                    if (fileToReplaceMusicBackup !== null) {
                                        queryParamObj.fileName = fileToReplaceMusicBackup;
                                    }
                                    p3 = clearTrackService.deleteDataParams(Constants.ClearTrackServiceBaseUrl + '/deleteFiles', queryParamObj, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id);
                                    promiseArray.push(p3)
                                }
                                if ((this.state.filesArr1.length === 0 && this.state.deleteFilesForLyrics !== null) ||
                                    (this.state.filesArr1.length > 0 && this.state.deleteFilesForLyrics !== null) ||
                                    (this.state.filesArr1.length === 0 && fileToReplaceLyricsBackup !== null) ||
                                    (this.state.filesArr1.length > 0 && fileToReplaceLyricsBackup !== null)) {

                                    // this.deleteAPICall("lyrics");
                                    let queryParamObj = {
                                        "songId": this.state.songDetails?.song_id,
                                        "songName": this.state.songDetails?.song_title,
                                        "module": "songs",
                                        "subModule": "lyrics",
                                        "tenantName": this.props.userContext?.active_tenant?.tenant_name,
                                        "tenantId": this.props.userContext?.active_tenant?.tenant_id,
                                        "isBoxUpload": 1
                                    }
                                    if (this.state.deleteFilesForLyrics !== null) {
                                        queryParamObj.fileName = this.state.deleteFilesForLyrics;
                                    }
                                    if (fileToReplaceLyricsBackup !== null) {
                                        queryParamObj.fileName = fileToReplaceLyricsBackup;
                                    }
                                    p4 = clearTrackService.deleteDataParams(Constants.ClearTrackServiceBaseUrl + '/deleteFiles', queryParamObj, this.props.userContext?.active_tenant?.tenant_id, this.props.division_id);

                                    promiseArray.push(p4)
                                }
                                if (promiseArray?.length > 0) {

                                    try {
                                        Promise.all(promiseArray).then((responses) => {
                                            // Get a JSON object from each of the responses
                                            responses.map((response) => {
                                                return response.data
                                            });
                                            if ((this.state.filesArr.length > 0 && this.state.filesArr[0].url.length === 0) && (this.state.filesArr1.length > 0 && this.state.filesArr1[0].url.length === 0)) {
                                                let extensionUrl = '/uploadFile';
                                                if (formDataLyrics !== null) {
                                                    formDataLyrics.append("songId", songIdForUpload);
                                                }
                                                clearTrackService.uploadFileFormData(Constants.ClearTrackServiceBaseUrl + extensionUrl, formDataLyrics, this.props.userContext?.active_tenant?.tenant_id)
                                                    .then((response) => {
                                                        if (response.data.error) {
                                                            formDataLyrics = null, formDataMusic = null, fileToReplaceLyricsBackup = null, fileToReplaceMusicBackup = null
                                                            this.setState({ isSubmit: false, postInitiated: false });
                                                            this.props?.notificationComponent(true, "fail");
                                                        } else {
                                                            formDataLyrics = null, formDataMusic = null, fileToReplaceLyricsBackup = null, fileToReplaceMusicBackup = null
                                                            this.setState({ isSubmit: false, postInitiated: false });
                                                            if (this.props?.proposedNewSong?.length > 0) {
                                                                this.props.newSongId(songIdForUpload, songTitle, this.props?.newSongRowIndex);
                                                                this.setState({ proposedNewSong: null });
                                                            } else {
                                                                this.props?.notificationComponent(true, "success");
                                                            }
                                                            if (this.props?.isMainTitle) {
                                                                if (this.props?.toggleRefreshMainTitleLicensor) {
                                                                    this.props?.toggleRefreshMainTitleLicensor()
                                                                }
                                                            } else {
                                                                if (this.props?.toggleRefreshPage) {
                                                                    this.props?.toggleRefreshPage('EpisodeClearance');
                                                                }
                                                            }
                                                        }
                                                    },
                                                        (err) => {
                                                            formDataLyrics = null, formDataMusic = null, fileToReplaceLyricsBackup = null, fileToReplaceMusicBackup = null
                                                            this.setState({ isSubmit: false, postInitiated: false });
                                                            this.props?.notificationComponent(true, "fail");
                                                        });
                                            } else {
                                                formDataLyrics = null, formDataMusic = null, fileToReplaceLyricsBackup = null, fileToReplaceMusicBackup = null
                                                this.setState({ isSubmit: false, postInitiated: false });
                                                if (this.props?.proposedNewSong?.length > 0) {
                                                    this.props.newSongId(songIdForUpload, songTitle, this.props?.newSongRowIndex);
                                                    this.setState({ proposedNewSong: null });
                                                } else {
                                                    this.props?.notificationComponent(true, "success");
                                                }
                                                if (this.props?.isMainTitle) {
                                                    if (this.props?.toggleRefreshMainTitleLicensor) {
                                                        this.props?.toggleRefreshMainTitleLicensor()
                                                    }
                                                } else {
                                                    if (this.props?.toggleRefreshPage) {
                                                        this.props?.toggleRefreshPage('EpisodeClearance');
                                                    }
                                                }
                                            }
                                        }).catch((error) => {
                                            formDataLyrics = null, formDataMusic = null, fileToReplaceLyricsBackup = null, fileToReplaceMusicBackup = null
                                            this.setState({ isSubmit: false, postInitiated: false });
                                            this.props?.notificationComponent(true, "fail");
                                        });


                                    } catch (error) {
                                        formDataLyrics = null, formDataMusic = null, fileToReplaceLyricsBackup = null, fileToReplaceMusicBackup = null
                                        this.setState({ isSubmit: false, postInitiated: false });
                                        this.props?.notificationComponent(true, "fail");
                                    }
                                }
                                else {

                                    this.setState({ isSubmit: false, postInitiated: false });
                                    formDataLyrics = null, formDataMusic = null, fileToReplaceLyricsBackup = null, fileToReplaceMusicBackup = null
                                    if (this.props?.proposedNewSong?.length > 0) {
                                        this.props.newSongId(songIdForUpload, songTitle, this.props?.newSongRowIndex);
                                        this.setState({ proposedNewSong: null });
                                    } else {
                                        this.props?.notificationComponent(true, "success");
                                    }
                                    if (this.props?.isMainTitle) {
                                        if (this.props?.toggleRefreshMainTitleLicensor) {
                                            this.props?.toggleRefreshMainTitleLicensor()
                                        }
                                    } else {
                                        if (this.props?.toggleRefreshPage) {
                                            this.props?.toggleRefreshPage('EpisodeClearance');
                                        }
                                    }
                                }
                            } else {

                                this.setState({ isSubmit: false, postInitiated: false });
                                if (this.props?.isMainTitle) {

                                    if (this.props?.toggleRefreshMainTitleLicensor) {
                                        this.props?.toggleRefreshMainTitleLicensor()
                                    }
                                } else {
                                    if (this.props?.toggleRefreshPage) {

                                        //this.props?.toggleRefreshPage('');
                                    }
                                }
                                if (this.props?.proposedNewSong?.length > 0) {

                                    this.props?.newSongId(songIdForUpload, songTitle, this.props?.newSongRowIndex);
                                    this.setState({ proposedNewSong: null });
                                } else {

                                    this.props?.notificationComponent(true, "success");
                                }
                            }

                        }
                    },
                    (err) => {
                        this.setState({ isSubmit: false, postInitiated: false });
                        this.props?.notificationComponent(true, "fail");
                    })

        }
    };
    downloadFile = (urlToDownload) => {
        window.open(urlToDownload);
    }
    render() {
        return (
            <>
                {this.state.deleteSongRecordLoading && <div className="ContentLoader">
                    <CircularProgress />
                </div>
                }
                {this.state.isFetchingSongDetails ?
                    <div className="ContentLoader">
                        <CircularProgress />
                    </div> :
                    <MDBContainer fluid className={'EditSongDetailsContainer ' + this.props?.origin}>
                        {this.props?.PopoverTitle && this.props?.origin !== "clearanceRecord" && <h5>{this.props?.PopoverTitle || ""}</h5>}

                        {this.props?.origin === "clearanceRecord" &&
                            (<MDBRow className="ModalHeader" id={"modal_header"}>
                                <MDBCol md={11} className="HeaderText" >{this.props?.PopoverTitle || ""}</MDBCol>
                                <MDBCol md={1} className="closeIcon" onClick={this.props?.handleClose} ><CancelIcon style={{ fill: "white" }} /></MDBCol>
                            </MDBRow>)
                        }
                        <MDBRow className="ModalBody">
                            {this.state.showMusicFileError === true ? <p className="errorMessage">Please upload Music file less than 4 MB.</p> : null}
                            {this.state.showLyricsFileError === true ? <p className="errorMessage">Please upload Lyrics file less than 4 MB.</p> : null}
                        </MDBRow>
                        <MDBRow>
                            <MDBCol md={4}>
                                <MDBRow>
                                    <BasicTextField
                                        label="Song Title"
                                        isMandatory={true}
                                        showMandatory={this.state.postInitiated}
                                        manatoryWarning={messages.mandatoryWarning}
                                        value={
                                            this.state.isPd === 0 ? this.state.songDetails?.song_title : this.state.publicDomainSongDetails?.song_title}
                                        onChange={(e) => this.handleChange("song_title", e.target.value)}
                                    />
                                </MDBRow>
                                {!this.state.isPd ? <>
                                    {
                                        this.state.songDetails?.writers_list?.map((writer, index) => (
                                            (writer.song_writer_is_delete === 0 ? <>
                                                <MDBCol md={8} className="col-content d-inline-block" id="writer">
                                                    <SearchSelectField
                                                        id={"writer-select"}
                                                        label={index === 0 ? "Writers" : ""}
                                                        options={this.state.writersOption}
                                                        multiple={false}
                                                        detail_selected={writer?.writer_id && writer?.writer_name ? {
                                                            value: writer?.writer_id,
                                                            text: writer?.writer_name
                                                        } : null}
                                                        valueSelected={this.selectedWriter(index)}
                                                        searchText={(e, value) => this.getEntity('writersOption', 'WRITER', e.target.value)}
                                                        searchSelect={true}
                                                    />
                                                </MDBCol>
                                                <MDBCol md={4} className="actionButons2 d-inline-block">
                                                    <MDBRow>
                                                        { //this.state?.editWriterNamePermission && !writer?.writer_id &&
                                                        <MDBCol md={1} className='pensquareicon'>
                                                            <MDBIcon
                                                                icon={'pen-square'}
                                                                onClick={(e) => {
                                                                    this.setState({
                                                                        writerMode: writer?.writer_id ? {
                                                                            value: writer?.writer_id,
                                                                            text: writer?.temp_writer_name,

                                                                        } : 'new', selectedWriterIndex: index
                                                                    })
                                                                }}
                                                            />
                                                        </MDBCol>
    }
                                                        <MDBCol md={1} className="removeActionIcon">
                                                            {
                                                                (writer.writer_name?.length > 0) ?
                                                                    <RemoveCircleIcon
                                                                        onClick={(e) =>
                                                                            this.removeField("writers_list", writer, index)
                                                                        }
                                                                    />
                                                                    : null
                                                            }
                                                        </MDBCol>
                                                        <MDBCol
                                                            md={1}
                                                            className={`${this.state.songDetails?.writers_list?.length === 1 ? 'addIconMargin' : ''}`}
                                                        >
                                                            {
                                                                (writer.writer_name?.length > 0 && index === (this.state.songDetails?.writers_list.length - 1)) ?
                                                                    <AddCircleIcon
                                                                        onClick={(e) =>
                                                                            this.addAdditionalField("writers_list", index)
                                                                        }
                                                                    /> : null
                                                            }
                                                        </MDBCol>
                                                    </MDBRow>
                                                </MDBCol>
                                            </> : null)
                                        ))
                                    }</> :
                                    <>
                                        {
                                            this.state.publicDomainSongDetails?.pd_genre?.map((musicType, index) => (
                                                (musicType.pd_genre_is_delete === 0 ? <>
                                                    <MDBCol md={6} className="col-content d-inline-block" id="musicType">
                                                        <SelectField
                                                            id={"music-type-select"}
                                                            label={index === 0 ? "Music Type" : ""}
                                                            defaultMenuText={"-Select-"}
                                                            options={this.state.musicTypeOptions || []}
                                                            value={musicType?.public_domain_genre_id || null}
                                                            onChange={(e, value) => this.handleMusicTypeListEdit("public_domain_genre_id", e.target.value, musicType, "pd_genre")}
                                                        />
                                                    </MDBCol>
                                                    <MDBCol md={6} className="actionButons2 d-inline-block">
                                                        <MDBRow className="actionsRow">
                                                            <MDBCol md={1}>
                                                                {
                                                                    (musicType.public_domain_genre?.length > 0) ?
                                                                        <RemoveCircleIcon
                                                                            onClick={(e) =>
                                                                                this.removeField("pd_genre", musicType, index)
                                                                            }
                                                                        />
                                                                        : null
                                                                }
                                                            </MDBCol>
                                                            <MDBCol
                                                                md={1}
                                                                className={`${this.state.publicDomainSongDetails?.pd_genre?.length === 1 ? 'addIconMargin' : ''}`}
                                                            >
                                                                {
                                                                    (musicType.public_domain_genre?.length > 0 && index === (this.state.publicDomainSongDetails?.pd_genre.length - 1)) ?
                                                                        <AddCircleIcon
                                                                            onClick={(e) =>
                                                                                this.addAdditionalField("pd_genre", index)
                                                                            }
                                                                        /> : null
                                                                }
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </MDBCol>
                                                </> : null)
                                            ))
                                        }
                                    </>
                                }
                            </MDBCol>
                            <MDBCol md={4}>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <div className="IconSize">
                                            <BasicLabel
                                                text={"Sheet Music"}
                                            />
                                            <div className="IconHeight font-size">
                                                {this.state.isMusicFileLoading ?
                                                    <div className="ContentLoader">
                                                        <CircularProgress />
                                                    </div> : <>
                                                        {
                                                            this.state.filesArr?.length === 0 ?
                                                                <BasicButton
                                                                    variant="outlined"
                                                                    icon="paperclip"
                                                                    // text="Add File"
                                                                    type="inline"
                                                                    inputType="file"
                                                                    multiple={false}
                                                                    id={'uploadMusicfile'}
                                                                    uploadFile={(file) => this.uploadFileMusic(file, false)}
                                                                />
                                                                :
                                                                this.state.filesArr?.length > 0 && this.state.filesArr?.map((item, index) => (
                                                                    <div className="files-display" key={"musicFile" + index}>
                                                                        {item?.url?.length > 0 ? <p style={{ cursor: "pointer" }} className="file-text" onClick={() => this.downloadFile(item.url)}>{item.Key}</p> : <p className="file-text-normal">{item.Key} </p>}
                                                                        <span className="icon-float">
                                                                            <BasicButton
                                                                                className="icon-float"
                                                                                variant="outlined"
                                                                                icon="exchange-alt"
                                                                                type="inline"
                                                                                inputType="file"
                                                                                multiple={false}
                                                                                id={'uploadMusicfile'}
                                                                                uploadFile={(file) => this.uploadFileMusic(file, true, item)}
                                                                            />
                                                                            <MDBIcon
                                                                                icon={"times"}
                                                                                onClick={() => this.deleteFilesForMusic(item)}
                                                                            />
                                                                        </span>
                                                                    </div>
                                                                ))
                                                        }
                                                    </>}
                                            </div>

                                        </div>
                                    </MDBCol>
                                    <MDBCol md={6} className='IconSize '>
                                        <div>
                                            <BasicLabel
                                                text={"Lyrics"}
                                            />
                                            {this.state.isLyricsFileLoading ?
                                                <div className="ContentLoader">
                                                    <CircularProgress />
                                                </div> : <>
                                                    {this.state.filesArr1?.length === 0 ?
                                                        <BasicButton
                                                            variant="outlined"
                                                            icon="paperclip"
                                                            // text="Add File"
                                                            type="inline"
                                                            inputType="file"
                                                            multiple={false}
                                                            id={'uploadLyricsfile'}
                                                            uploadFile={(file) => this.uploadFileLyrics(file, false)}
                                                        />
                                                        :
                                                        this.state.filesArr1?.length > 0 && this.state.filesArr1?.map((item, index) => (
                                                            <div className="files-display" key={"LyricsFile" + index}>
                                                                {item?.url?.length > 0 ? <p style={{ cursor: "pointer" }} className="file-text" onClick={() => this.downloadFile(item.url)}>{item.Key} </p> : <p className="file-text-normal">{item.Key} </p>}
                                                                <span className="icon-float">
                                                                    <BasicButton
                                                                        variant="outlined"
                                                                        icon="exchange-alt"
                                                                        // text="Add File"
                                                                        type="inline"
                                                                        inputType="file"
                                                                        multiple={false}
                                                                        id={'uploadLyricsfile'}
                                                                        uploadFile={(file) => this.uploadFileLyrics(file, true, item)}
                                                                    />
                                                                    <MDBIcon
                                                                        icon={"times"}
                                                                        onClick={() => this.deleteFilesForLyrics(item)}
                                                                    />
                                                                </span>
                                                            </div>
                                                        ))
                                                    }
                                                </>}
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        {!this.state.isPd ?
                                            <>
                                                <MDBRow>
                                                    <BasicCheckbox
                                                        id="is_arrangement"
                                                        label="Is Arrangement"
                                                        align="end"
                                                        checked={this.state.songDetails?.is_arrangement}
                                                        onChange={(e) => this.handleChange("is_arrangement", e.target.checked)}
                                                    />
                                                </MDBRow>
                                                <MDBRow>
                                                    <BasicCheckbox
                                                        id="is_all_promo_use_restricted"
                                                        label="All Promo Use Restricted"
                                                        align="end"
                                                        checked={this.state.songDetails?.is_all_promo_use_restricted}
                                                        onChange={(e) => {
                                                            this.handleChange("is_all_promo_use_restricted", e.target.checked)
                                                            this.handleChange("is_limited_promo_use", false)
                                                        }}
                                                    />
                                                </MDBRow>
                                                {/* <MDBRow>
                                                    <BasicCheckbox
                                                        id="is_limited_promo_use"
                                                        label="Limited Promo Use"
                                                        align="end"
                                                        checked={this.state.songDetails?.is_limited_promo_use}
                                                        onChange={(e) => {
                                                            this.handleChange("is_limited_promo_use", e.target.checked)
                                                            this.handleChange("is_all_promo_use_restricted", false)
                                                        }}
                                                    />
                                                </MDBRow> */}
                                                <MDBRow>
                                                    <BasicCheckbox
                                                        id="is_studio_owned"
                                                        label="Studio Owned"
                                                        align="end"
                                                        checked={this.state.songDetails?.is_studio_owned}
                                                        onChange={(e) => this.handleChange("is_studio_owned", e.target.checked)}
                                                    />
                                                </MDBRow>
                                            </> :
                                            <>
                                                <MDBRow>
                                                    <BasicCheckbox
                                                        id="song_is_do_not_use"
                                                        label="Do not Use"
                                                        align="end"
                                                        checked={this.state.isPd === 0 ? this.state.songDetails?.song_is_do_not_use : this.state.publicDomainSongDetails?.song_is_do_not_use}
                                                        onChange={(e) => this.handleChange("song_is_do_not_use", e.target.checked)}
                                                    />
                                                </MDBRow>
                                                <MDBRow>
                                                    <BasicCheckbox
                                                        id="is_coa"
                                                        label="COA"
                                                        align="end"
                                                        checked={this.state.isPd === 0 ? this.state.songDetails?.is_coa : this.state.publicDomainSongDetails?.is_coa}
                                                        onChange={(e) => this.handleChange("is_coa", e.target.checked)}
                                                    />
                                                </MDBRow>
                                            </>
                                        }
                                    </MDBCol>
                                    <MDBCol md={6}>
                                        {!this.state.isPd &&
                                            <>
                                                <MDBRow>
                                                    <BasicCheckbox
                                                        id="is_library"
                                                        label="Library"
                                                        align="end"
                                                        checked={this.state.songDetails?.is_library}
                                                        onChange={(e) => this.handleChange("is_library", e.target.checked)}
                                                    />
                                                </MDBRow>
                                                <MDBRow>
                                                    <BasicCheckbox
                                                        id="is_coa"
                                                        label="COA"
                                                        align="end"
                                                        checked={this.state.songDetails?.is_coa}
                                                        onChange={(e) => this.handleChange("is_coa", e.target.checked)}
                                                    />
                                                </MDBRow>
                                                <MDBRow>
                                                    <BasicCheckbox
                                                        id="is_contains_simple"
                                                        label="Contains Sample(s)"
                                                        align="end"
                                                        checked={this.state.songDetails?.is_contains_simple}
                                                        onChange={(e) => this.handleChange("is_contains_simple", e.target.checked)}
                                                    />
                                                </MDBRow>
                                                <MDBRow>
                                                    <BasicCheckbox
                                                        id="song_is_do_not_use"
                                                        label="Do not Use"
                                                        align="end"
                                                        checked={this.state.isPd === 0 ? this.state.songDetails?.song_is_do_not_use : this.state.publicDomainSongDetails?.song_is_do_not_use}
                                                        onChange={(e) => this.handleChange("song_is_do_not_use", e.target.checked)}
                                                    />
                                                </MDBRow>
                                            </>}
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                            <MDBCol md={4}>
                                <MDBRow>
                                    <MDBCol md={6}>
                                        <BasicCheckbox
                                            id="is_pd"
                                            label="Public Domain"
                                            align="end"
                                            checked={this.state.isPd}
                                            disabled={this.props?.mode === "Edit" ? true : false}
                                            onChange={(e) => this.handleChange("isPd", e.target.checked)}
                                        />
                                    </MDBCol>
                                    <MDBCol md={6}>
                                        <BasicCheckbox
                                            id="is_active"
                                            label="Inactive"
                                            align="end"
                                            checked={!this.state.songDetails?.is_active}
                                            onChange={(e) => this.handleChange("is_active", e.target.checked)}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <BasicTextArea
                                    label={"Notes"}
                                    rows={4}
                                    value={this.state.isPd === 0 ? this.state.songDetails?.notes : this.state.publicDomainSongDetails?.notes}
                                    onChange={(e) =>
                                        this.handleChange("notes", e.target.value)
                                    }
                                />
                            </MDBCol>
                        </MDBRow>
                        {!this.state.isPd ? <>
                            <MDBRow className='mt2'>
                                <MDBCol md={6} id="table-width" className="Sync_Licensor">
                                    <span className='table-name'>Sync Licensors</span>
                                    <p className='errorMessage' >{this.state.MandatorySyncLicensorsError && `Please enter Licensor and Share field's value`}</p>
                                    <TableComponent
                                        id={"Sync-Licensors"}
                                        list={this.state.songDetails?.licensors_list || []}
                                        config={LicenseHeading}
                                        isLoading={false}
                                        hyperLinkNavigationCallback={this.navigationCallback}
                                        handleAddNewItem={this.handleAddNewItem.bind(this, "Sync-Licensors")}
                                        editCallback={this.handleInlineEdits.bind(this, "Sync-Licensors")}
                                        saveCallback={this.saveInlineEdits.bind(this, "Sync-Licensors")}
                                        handleDelCallBack={(id,dataitem,index)=>this.handleInlineDelete(id,dataitem,index, "Sync-Licensors")}
                                        readCallback={this.handleReadCallback.bind(this, "Sync-Licensors")}
                                        searchString={(value) => this.getEntity("licensors", "LICENSOR", value, "Sync-Licensors")}
                                        handleEditPayeeModal={this.handleSyncLicModal.bind(this)}
                                        addItemToList={true}
                                        validateNewItem={(newDataItem) => {
                                            if (newDataItem?.licensor_share && validateNumDotOnly(newDataItem?.licensor_share) && newDataItem?.licensor?.value) {
                                                this.setState({ MandatorySyncLicensorsError: false })
                                                return true;
                                            } else {
                                                this.setState({ MandatorySyncLicensorsError: true })
                                                return false;
                                            }
                                        }}

                                    />
                                </MDBCol>
                                <MDBCol md={6} className="cue-sheet-publsiher" id="table-width">
                                    <span className='table-name'>
                                        {`Cue Sheet Publishers${this.state.selectedLicensor ?
                                            ` for ${this.state.selectedLicensor?.licensor_name}` :
                                            `${this.state.songDetails?.licensors_list?.length > 0 ?
                                                ` for ${this.state.songDetails?.licensors_list[0]?.licensor_name}` :
                                                ''}`
                                            }`
                                        }
                                    </span>
                                    <p className='errorMessage' >{this.state.MandatoryCueSheetPublishersError && `Please enter Cue Sheet Publisher, Affiliation and Share field's value`}</p>
                                    {CueSheetHeading?.headings[2]?.selectOptions?.length > 0 &&
                                        <TableComponent
                                            id={"Cue-Sheet-Publishers"}
                                            list={this.state.cueSheetPublisherList}
                                            config={CueSheetHeading}
                                            isLoading={false}
                                            handleAddNewItem={this.handleAddNewItem.bind(this, "Cue-Sheet-Publishers")}
                                            editCallback={this.handleInlineEdits.bind(this, "Cue-Sheet-Publishers")}
                                            handleDelCallBack={(id,dataitem,index)=>this.handleInlineDelete(id,dataitem,index, "Cue-Sheet-Publishers")}
                                            saveCallback={this.saveInlineEdits.bind(this, "Cue-Sheet-Publishers")}
                                            readCallback={this.handleReadCallback.bind(this, "Cue-Sheet-Publishers")}
                                            searchString={(value) => this.getStaticData("cueSheetPublishers", "lu_cue_sheet_publisher")}
                                            addItemToList={this.state.songDetails?.licensors_list?.length > 0 && true}
                                            handleEditPayeeModal={this.handleCueSheetPubModal.bind(this)}
                                            validateNewItem={(newDataItem) => {
                                                if (newDataItem?.cue_sheet_publisher && newDataItem?.affiliation && newDataItem?.cue_sheet_share && validateNumDotOnly(newDataItem?.cue_sheet_share)) {
                                                    this.setState({ MandatoryCueSheetPublishersError: false })
                                                    return true;
                                                } else {
                                                    this.setState({ MandatoryCueSheetPublishersError: true })
                                                    return false;
                                                }
                                            }
                                            }
                                        />
                                    }
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='mt2'>
                                <MDBCol md={6} id="table-width" className="performers-table">
                                    <span className='table-name'>Performers</span>
                                    <p className='errorMessage' >{this.state.MandatoryPerformersError && `Please enter Performer field's value`}</p>
                                    <TableComponent
                                        id={"performers"}
                                        list={this.state.songDetails?.performers_list}
                                        config={PerformerHeading}
                                        isLoading={false}
                                        hyperLinkNavigationCallback={this.navigationCallback}
                                        handleAddNewItem={this.handleAddNewItem.bind(this, "performers")}
                                        handleDelCallBack={(id,dataitem,index)=>this.handleInlineDelete(id,dataitem,index, "performers")}
                                        editCallback={this.handleInlineEdits.bind(this, "performers")}
                                        saveCallback={this.saveInlineEdits.bind(this, "performers")}
                                        readCallback={this.handleReadCallback.bind(this, "performers")}
                                        handleEditPayeeModal={this.handlePerformerModal.bind(this)}
                                        searchString={(value) => this.getStaticData("performers", "lu_performer")}
                                        addItemToList={true}
                                    />
                                </MDBCol>
                                <MDBCol md={6} className="Sync_Licensor Licensors-Wayne" id="table-width">
                                    <span className='table-name'>
                                        {`Licensors${this.state.selectedPerformer ?
                                            ` for ${this.state.selectedPerformer?.performer_name}` :
                                            `${this.state.songDetails?.performers_list?.length > 0 ?
                                                ` for ${this.state.songDetails?.performers_list[0]?.performer_name}` :
                                                ''}`
                                            }`
                                        }
                                    </span>
                                    <p className='errorMessage' >{this.state.MandatoryLicensorsWayne && `Please enter Licensor and Share field's value`}</p>
                                    <TableComponent
                                        id={"Licensors-Wayne"}
                                        list={this.state.performerLicensorList}
                                        config={LicensorsWayneHeading}
                                        isLoading={false}
                                        handleAddNewItem={this.handleAddNewItem.bind(this, "Licensors-Wayne")}
                                        handleDelCallBack={(id,dataitem,index)=>this.handleInlineDelete(id,dataitem,index,  "Licensors-Wayne")}
                                        editCallback={this.handleInlineEdits.bind(this, "Licensors-Wayne")}
                                        saveCallback={this.saveInlineEdits.bind(this, "Licensors-Wayne")}
                                        readCallback={this.handleReadCallback.bind(this, "Licensors-Wayne")}
                                        handleEditPayeeModal={this.handlePerformerLicModal.bind(this)}
                                        searchString={(value) => this.getEntity("licensors", "LICENSOR", value, "Licensors-Wayne")}
                                        addItemToList={this.state.songDetails?.performers_list?.length && true}
                                        validateNewItem={(newDataItem) => {
                                            if (newDataItem?.performer_licensor && newDataItem?.performer_licensor_share && validateNumDotOnly(newDataItem?.performer_licensor_share)) {
                                                this.setState({ MandatoryLicensorsWayne: false })
                                                return true;
                                            } else {
                                                this.setState({ MandatoryLicensorsWayne: true })
                                                return false;
                                            }
                                        }}
                                    />
                                </MDBCol>
                            </MDBRow>

                        </> :
                            <MDBRow className="pd-composer-table mt1">
                                <MDBCol id="table-width" md={9}>
                                    <span className='table-name'>PD Composer</span>
                                    <p className='errorMessage' >{this.state.MandatoryPdComposerError && `Please enter Composer and Year field's value`}</p>
                                    <TableComponent
                                        id={"pd-composers"}
                                        list={this.state.publicDomainSongDetails?.pd_composer}
                                        config={PdComposers}
                                        isLoading={false}
                                        handleAddNewItem={this.handleAddNewItem.bind(this, "pd-composers")}
                                        editCallback={this.handleInlineEdits.bind(this, "pd-composers")}
                                        saveCallback={this.saveInlineEdits.bind(this, "pd-composers")}
                                        // handleDelCallBack={(id,dataitem,index)=>this.handleInlineDelete(id,dataitem,index, "pd-composers")}
                                        readCallback={this.handleReadCallback.bind(this, "pd-composers")}
                                        searchString={(value) => this.getEntity("pdComposers", "PD_COMPOSER", value)}
                                        addItemToList={true}
                                        validateNewItem={(newDataItem) => {
                                            if (newDataItem?.pd_composer?.value && newDataItem?.year && validateNumbersOnly(newDataItem?.year)) {
                                                this.setState({ MandatoryPdComposerError: false })
                                                return true;
                                            } else {
                                                this.setState({ MandatoryPdComposerError: true })
                                                return false;
                                            }
                                        }}
                                    />
                                </MDBCol>
                            </MDBRow>
                            // <PublicDomainSong
                            //     songDetails={this.state.publicDomainSongDetails}
                            //     musicTypeOptions={this.state.musicTypeOptions}
                            //     postInitiated={this.state.postInitiated}
                            //     messages={messages}
                            //     handleChange={this.handlePdSongOnchange}
                            //     handleListEdit={this.handleMusicTypeListEdit}
                            //     removeField={this.removeField}
                            //     addAdditionalField={this.addAdditionalField}
                            //     tableConfig={PdComposers}
                            //     handleAddNewItem={this.handleAddNewItem}
                            //     handleInlineEdits={this.handleInlineEdits}
                            //     saveInlineEdits={this.saveInlineEdits}
                            //     searchString={this.getEntity}
                            //     pdComposers={this.state.pdComposers}
                            // />
                        }

                        <MDBRow>
                            <MDBRow className="p-2">
                                <MDBCol md={9}></MDBCol>
                                <MDBCol md={1}>
                                    <BasicButton
                                        disabled={this.props?.songModalPermissions?.edit === 1 ? ((this.state.editingSyncLicensors === true || this.state.editingCueSheetPublishers === true || this.state.editingPerformers === true || this.state.editingLicensorsWayne === true || this.state.editingPdComposers === true) ? true : false) : true}
                                        className="btnwidth"
                                        variant="contained"
                                        type="inline"
                                        text={this.state.isSubmit ? <CircularProgress color="inherit" size={18} /> : "Save"}
                                        onClick={()=>this.handleSubmit(this.state.isPd===0?this.state.songDetails:this.state.publicDomainSongDetails)}

                                    />
                                </MDBCol>
                                <MDBCol md={1} >
                                    <BasicButton
                                        type="inline"
                                        variant="outlined"
                                        text={"Close"}
                                        onClick={this.props?.handleClose}
                                        disabled={false}
                                    />
                                </MDBCol>
                                {this.props?.mode === "Edit" && 
                                <MDBCol md={1}>
                                <BasicButton
                                    disabled={this.state.songDetails?.is_Not_Delete_Song}
                                    className={`${this.state.songDetails?.is_Not_Delete_Song ?"": "delSong"}`}
                                    icon={"trash-alt"}
                                    text={this.state.isDeleted ? <CircularProgress color="inherit" size={18} /> : " Delete "}
                                    onClick={()=> this.setState({showError: true})}
                                />
                                </MDBCol>
                                }
                            </MDBRow>
                        </MDBRow>
                        {this.state.writerMode && <MainViewModalComponent
                            open={this.state.writerMode ? true : false}
                            handleClose={() =>
                                this.setState({
                                    writerMode: null,
                                    selectedWriterIndex: null,
                                })
                            }
                            headerText={"Writer"}
                            mode={this.state.writerMode !== 'new' ? "Edit" : 'Add'}
                            writerId={this.state.writerMode || null}
                            modalName={"On Fly Lookup"}
                            type={'writer'}
                            handleAddWriter={(writerData) => {
                                let index = this.state.selectedWriterIndex
                                this.handleListEdit('writers_list', 'writer_id', writerData?.value || null, index)
                                this.handleListEdit('writers_list', 'writer_name', writerData?.text || null, index)
                                this.handleListEdit('writers_list', 'temp_writer_name', writerData?.temp_text || null, index)
                            }}
                            songDetails={this.state.songDetails}
                        />}
                        {this.state.openCueSheetPubModal && <MainViewModalComponent
                            open={this.state.openCueSheetPubModal ? true : false}
                            handleClose={() =>
                                this.setState({
                                    openCueSheetPubModal: null,
                                    modalIndex: null,
                                })
                            }
                            headerText={"Cue Sheet Publisher"}
                            mode={"Edit"}
                            cueSheetPubId={this.state.openCueSheetPubModal || null}
                            modalName={"On Fly Lookup"}
                            type={'cueSheetPublisher'}
                            handleCueSheetPubUpdate={(cueSheetPubInfo) => {
                                //this.getStaticData("cueSheetPublishers", "lu_cue_sheet_publisher")
                                let cueSheetPublisherList = [...this.state.cueSheetPublisherList];
                                let row = this.state.modalIndex
                                let songDetails = JSON.parse(JSON.stringify(this.state.songDetails))
                                let selectedLicensorIndex = songDetails?.licensors_list?.findIndex(item => item.song_licensor_id === this.state.selectedLicensor?.song_licensor_id)
                                songDetails.licensors_list[selectedLicensorIndex].cue_sheet_publisher_list[row].cue_sheet_publisher_name = cueSheetPubInfo?.text
                                if (songDetails.licensors_list[selectedLicensorIndex].cue_sheet_publisher_list[row]?.cue_sheet_publisher?.text) {
                                    songDetails.licensors_list[selectedLicensorIndex].cue_sheet_publisher_list[row].cue_sheet_publisher.text = cueSheetPubInfo?.text
                                }
                                cueSheetPublisherList[row].cue_sheet_publisher_name = cueSheetPubInfo?.text
                                if (cueSheetPublisherList[row]?.cue_sheet_publisher?.text) {
                                    cueSheetPublisherList[row].cue_sheet_publisher.text = cueSheetPubInfo?.text
                                }
                                this.setState({ songDetails: songDetails, openCueSheetPubModal: null, modalIndex: null, cueSheetPublisherList: cueSheetPublisherList })
                            }}
                        />}
                        {this.state.openPerformerModal && <MainViewModalComponent
                            open={this.state.openPerformerModal ? true : false}
                            handleClose={() =>
                                this.setState({
                                    openPerformerModal: null,
                                    modalIndex: null,
                                })
                            }
                            headerText={"Performer"}
                            mode={"Edit"}
                            performerId={this.state.openPerformerModal || null}
                            modalName={"On Fly Lookup"}
                            type={'performer'}
                            handlePerformerUpdate={(performerInfo) => {
                                let row = this.state.modalIndex
                                let songDetails = JSON.parse(JSON.stringify(this.state.songDetails))
                                songDetails.performers_list[row].performer_name = performerInfo?.text
                                if (songDetails.performers_list[row]?.performer?.text) {
                                    songDetails.performers_list[row].performer.text = performerInfo?.text
                                }
                                this.setState({ songDetails: songDetails, openPerformerModal: null, modalIndex: null })
                            }}
                        />}
                        {this.state.openSyncLicensorModal && (
                            <MainViewModalComponent
                                open={this.state.openSyncLicensorModal}
                                handleClose={() => { this.setState({ openSyncLicensorModal: null, modalIndex: null }) }}
                                headerText={"Licensor"}
                                mode={"Edit"}
                                modalName={"Edit Licensor"}
                                id={this.state.openSyncLicensorModal || null}
                                songModalUpdate={(licensorData) => {
                                    //this.getEntity("licensors", "LICENSOR", licensorData?.text?.slice(0,3), "Sync-Licensors")
                                    let row = this.state.modalIndex
                                    let songDetails = JSON.parse(JSON.stringify(this.state.songDetails))
                                    songDetails.licensors_list[row].licensor_name = licensorData?.text
                                    if (songDetails.licensors_list[row]?.licensor?.text) {
                                        songDetails.licensors_list[row].licensor.text = licensorData?.text
                                    }
                                    this.setState({ songDetails: songDetails })
                                }}
                                commonPermissions={this.props?.userContext?.active_tenant?.common_permissions}
                            />
                        )}
                        {this.state.openPerformerLicModal && (
                            <MainViewModalComponent
                                open={this.state.openPerformerLicModal}
                                handleClose={() => { this.setState({ openPerformerLicModal: null, modalIndex: null }) }}
                                headerText={"Licensor"}
                                mode={"Edit"}
                                modalName={"Edit Licensor"}
                                id={this.state.openPerformerLicModal || null}
                                songModalUpdate={(licensorData) => {
                                    let performerLicensorList = [...this.state.performerLicensorList];
                                    let row = this.state.modalIndex
                                    let songDetails = JSON.parse(JSON.stringify(this.state.songDetails))
                                    let selectedPerformerIndex = songDetails?.performers_list?.findIndex(item => item.song_performer_id === this.state.selectedPerformer?.song_performer_id)
                                    songDetails.performers_list[selectedPerformerIndex].performer_licensor_list[row].performer_licensor_name = licensorData?.text
                                    if (songDetails.performers_list[selectedPerformerIndex].performer_licensor_list[row]?.performer_licensor?.text) {
                                        songDetails.performers_list[selectedPerformerIndex].performer_licensor_list[row].performer_licensor.text = licensorData?.text
                                    }
                                    performerLicensorList[row].performer_licensor_name = licensorData?.text
                                    if (performerLicensorList[row]?.performer_licensor?.text) {
                                        performerLicensorList[row].performer_licensor.text = licensorData?.text
                                    }
                                    this.setState({ songDetails: songDetails, performerLicensorList: performerLicensorList })
                                }}
                                commonPermissions={this.props?.userContext?.active_tenant?.common_permissions}
                            />
                        )}
                        { this.state.showError &&
                            <MessageModal
                            open={this.state.showError || false}
                            title={"Delete Song"}
                            message={`Are you sure want to delete the song "${this.state.isPd === 0 ? this.state.songDetails?.song_title : this.state.publicDomainSongDetails?.song_title}"?`}
                            hideCancel={false}
                            primaryButtonText={"OK"}
                            secondaryButtonText={"Cancel"}
                            onConfirm={() => this.onConfirm()}
                            handleClose={() =>this.setState({showError: false})} />
                        }
                    </MDBContainer>
                }
            </>
        )
    }
}

export default withUserContext(EditSongDetails);

